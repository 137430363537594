import { Card, CardBody, CardFooter, CardHeader, Typography } from '@material-tailwind/react';
import React from 'react';
import { FaHotel } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';
import { useUserContext } from '../../context';

export default function HotelStatCard({ hotelId, hotelName, totalRooms, checkedInRooms, income }) {
    const navigate = useNavigate();
    const { setDefaultHotel } = useUserContext();

    function formatNumber(number) {
        const options = { maximumFractionDigits: 2 }
        return Intl.NumberFormat("en-US", options).format(number);
    }

    return (
        <Card className="border border-blue-gray-100 shadow-sm">
            <CardHeader
                variant="gradient"
                color="orange"
                floated={false}
                shadow={false}
                className="absolute grid h-20 w-20 place-items-center cursor-pointer mt-10"
                onClick={() => {
                    setDefaultHotel(hotelId);
                    navigate("/manager/dashboard");
                }}>
                <FaHotel className="h-8 w-8" />
            </CardHeader>
            <CardBody className="p-4 text-right" >
                <Typography variant="h5" className="font-bold text-gray-700 select-none">
                    {hotelName}
                </Typography>
                <Typography variant="h6" color="blue-gray text-right">
                    <Typography className="text-blue-300 cursor-pointer select-none font-bold">
                        Checked In:  {checkedInRooms}
                    </Typography>
                    <Typography className="text-green-300 cursor-pointer select-none font-bold">
                        Free: {totalRooms - checkedInRooms}
                    </Typography>
                    <Typography className="text-gray-600 select-none font-bold">
                        Occupancy: {(totalRooms ? ((checkedInRooms / totalRooms) * 100).toFixed(0) : 0)}%
                    </Typography>
                </Typography>
            </CardBody>

            <CardFooter className="border-t border-blue-gray-50 p-4 flex">

            </CardFooter>
        </Card>
    )
}
