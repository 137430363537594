import React, { useContext, useMemo, useReducer } from "react";

export const MaterialTailwind = React.createContext(null);
MaterialTailwind.displayName = "MaterialTailwindContext";

export function reducer(state, action) {
    switch (action.type) {
        case "OPEN_SIDENAV": {
            return { ...state, openSidenav: action.value };
        }
        case "SIDENAV_TYPE": {
            return { ...state, sidenavType: action.value };
        }
        case "SIDENAV_COLOR": {
            return { ...state, sidenavColor: action.value };
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`);
        }
    }
}

export function MaterialTailwindProvider({ children }) {

    const initialState = {
        openSidenav: false,
        sidenavColor: "green",
        sidenavType: "white"
    };

    const [controller, dispatch] = useReducer(reducer, initialState);
    const value = useMemo(() => [controller, dispatch], [controller, dispatch]);

    return (
        <MaterialTailwind.Provider value={value}>
            {children}
        </MaterialTailwind.Provider>
    )
}

export function useMaterialTailwind() {
    const context = useContext(MaterialTailwind);
    if (!context) {
        throw new Error(
            "useMaterialTailwind should be used inside the MaterialTailwindProvider."
        );
    }
    return context;
}




