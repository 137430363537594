import { Dialog, DialogBody, DialogFooter, DialogHeader, IconButton, Button } from '@material-tailwind/react';
import { useFormik } from 'formik';
import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ClockLoader } from 'react-spinners';
import * as Yup from 'yup';
import { useUserContext } from '../../context';
import { useApi } from '../../hooks/useApi';
import { MdDelete } from "react-icons/md";

export default function Guests() {
    const navigate = useNavigate();
    const { uploadDocument, addGuest, searchGuest, getImageUrl } = useApi();
    const { fetchAccessToken, defaultHotel } = useUserContext();
    const [isLoading, setIsLoading] = useState(false);
    const [isShowProofImage, setShowProofImage] = useState(false);
    const [imageUrl, setImageUrl] = useState("");
    const fileInputRef = useRef(null);

    let guestFormik = useFormik({
        initialValues: {
            mobile: "",
            email: "",
            name: "",
            address: "",
            purposeOfVisit: "personal",
            companyGST: "",
            companyDetails: "",
            documentType: "",
            documentNumber: "",
            documentS3Ids: [],
            attachments: [],
            showCheckIn: false
        },
        validationSchema: Yup.object({
            mobile: Yup.string().matches(/^[0-9]{10}$/, 'Mobile number must be exactly 10 digits').required('Mobile Number is required'),
            email: Yup.string().email('Invalid email format'),
            name: Yup.string().required("Guest full name is required"),
            address: Yup.string().required("Guest address is required"),
            documentType: Yup.string().required("Document Type is required"),
            documentNumber: Yup.string().required("Document Number is required"),
            documentS3Ids: Yup.array().of(Yup.string()).min(1, 'At least one document copy is required')
        }),
        onSubmit: async (values, actions) => {
            try {
                setIsLoading(true);
                const token = await fetchAccessToken();
                await addGuest(defaultHotel, { ...values, attachments: [] }, token);
                let confirm = window.confirm("Guest details saved. Do you want to checkin the guest?");
                if (confirm) {
                    navigate("/manager/checkin", { state: { guestId: values.mobile, guestName: values.name } });
                } else {
                    guestFormik.resetForm();
                }
            } catch (ex) {
                alert("Error occurred while adding guest.");
            } finally {
                setIsLoading(false);
            }
        }
    });

    function convertBlobToBase64(blob) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                resolve(reader.result);
            };
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });
    }

    let handleUploadAttachments = async () => {
        try {
            if (guestFormik.values.attachments.length < 1) {
                alert("Please select attachments");
                return;
            }
            setIsLoading(true);
            const token = await fetchAccessToken();
            const responses = await Promise.all(guestFormik.values.attachments.map(async (attachment) => {
                let base64String = await convertBlobToBase64(attachment);
                return await uploadDocument(base64String, token);
            }));
            guestFormik.setFieldValue('documentS3Ids', responses);
            guestFormik.setFieldValue('attachments', []);

        } catch (ex) {
            alert(ex);
        } finally {
            setIsLoading(false);
        }
    };

    let handleSearchGuestInternal = async () => {
        let mobile = guestFormik.values.mobile;
        if (mobile && isValidMobileNumber(mobile)) {
            try {
                setIsLoading(true);
                const token = await fetchAccessToken();
                const guest = await searchGuest(defaultHotel, mobile, token);
                if (guest) {
                    guestFormik.setFieldValue('email', guest.email);
                    guestFormik.setFieldValue('name', guest.name);
                    guestFormik.setFieldValue('address', guest.address);
                    guestFormik.setFieldValue('purposeOfVisit', guest.purposeOfVisit);
                    guestFormik.setFieldValue('companyGST', guest.companyGST);
                    guestFormik.setFieldValue('companyDetails', guest.companyDetails);
                    guestFormik.setFieldValue('documentType', guest.documentType);
                    guestFormik.setFieldValue('documentNumber', guest.documentNumber);
                    guestFormik.setFieldValue('documentS3Ids', guest.documentS3Ids);
                    guestFormik.setFieldValue('showCheckIn', true);
                }
            } catch (ex) {

            } finally {
                setIsLoading(false);
            }
        }
    }

    function isValidMobileNumber(number) {
        const regex = /^[0-9]{10}$/;
        return regex.test(number);
    }

    let handleSubmitInternal = () => {
        guestFormik.handleSubmit();
    };

    let handleProofView = async (documentS3Id) => {
        try {
            setIsLoading(true);
            const token = await fetchAccessToken();
            const imageUrl = await getImageUrl(documentS3Id, token);
            if (imageUrl) {
                setImageUrl(imageUrl);
                setShowProofImage(true);
            }
        } catch (ex) {
            alert("Error occurred while viewing the Proof.");
        } finally {
            setIsLoading(false);
        }
    };

    const handleDeleteAttachment = (index) => {
        const newItems = guestFormik.values.attachments.filter((_, i) => i !== index);
        guestFormik.setFieldValue('attachments', newItems);
    };

    const handleFileChange = (e) => {
        setIsLoading(true);
        let file = e.target.files[0];
        if (file && (file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png')) {
            compressImage(file, (compressedBlob) => {
                guestFormik.setFieldValue('attachments', [...guestFormik.values.attachments, compressedBlob]);
                if (fileInputRef.current) {
                    fileInputRef.current.value = '';
                }
                setIsLoading(false);
            });
        } else {
            setIsLoading(false);
            alert('Please upload a JPEG or PNG image.');
        }
    };

    const compressImage = (file, callback) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const img = new Image();
            img.src = e.target.result;
            img.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                canvas.width = img.width;
                canvas.height = img.height;
                ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

                const fileType = file.type;
                const isJPEG = fileType === 'image/jpeg' || fileType === 'image/jpg';

                let quality = isJPEG ? 1.0 : undefined;
                const maxFileSize = 1000000; // 1MB

                const compress = () => {
                    canvas.toBlob((blob) => {
                        if (blob.size <= maxFileSize || (isJPEG && quality < 0.1)) {
                            callback(blob);
                        } else if (isJPEG) {
                            quality -= 0.1;
                            compress();
                        } else {
                            // For PNG, if the first compression is too large, we can't reduce quality, so we just use the first result
                            callback(blob);
                        }
                    }, fileType, quality);
                };

                compress();
            };
        };
        reader.readAsDataURL(file);
    };

    return (
        <section className="py-1 bg-blueGray-50">
            <div className="w-full lg:w-8/12 px-4 mx-auto mt-6">
                <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
                    <div className="rounded-t bg-white mb-0 px-6 py-6">
                        <div className="text-center flex justify-between">
                            <h6 className="text-blueGray-700 text-xl font-bold">
                                Add Guest
                            </h6>
                        </div>
                    </div>
                    <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                        <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                            Basic Information
                        </h6>
                        <div className="flex flex-wrap">
                            <div className="relative w-full mb-3 px-4">
                                <div className="relative w-full mb-3">
                                    <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" >
                                        Mobile Number<span className="text-red-500">*</span>
                                    </label>
                                    <div className="flex justify-center">
                                        <input type="tel" id="mobile" name="mobile" className="sm:w-100 lg:w-150 border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 pl-1" onChange={guestFormik.handleChange} onBlur={guestFormik.handleBlur} value={guestFormik.values.mobile} />
                                        <button className="bg-black text-white hover:bg-black font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-2 ease-linear transition-all duration-150 ml-2" type="button" onClick={handleSearchGuestInternal}>
                                            Search
                                        </button>
                                    </div>
                                    <div className="flex justify-left">
                                        {guestFormik.touched.mobile && guestFormik.errors.mobile ? (
                                            <div className="text-red-500">{guestFormik.errors.mobile}</div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                            <div className="w-full lg:w-6/12 px-4">
                                <div className="relative w-full mb-3">
                                    <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" >
                                        Full Name<span className="text-red-500">*</span>
                                    </label>
                                    <input type="text" id="name" name="name" className="uppercase border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" onChange={guestFormik.handleChange} onBlur={guestFormik.handleBlur} value={guestFormik.values.name} />
                                    {guestFormik.touched.name && guestFormik.errors.name ? (
                                        <div className="text-red-500">{guestFormik.errors.name}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="w-full lg:w-6/12 px-4">
                                <div className="relative w-full mb-3">
                                    <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" >
                                        Email
                                    </label>
                                    <input type="text" id="email" name="email" className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" onChange={guestFormik.handleChange} onBlur={guestFormik.handleBlur} value={guestFormik.values.email} />
                                </div>
                            </div>
                            <div className="w-full lg:w-12/12 px-4">
                                <div className="relative w-full mb-3">
                                    <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" >
                                        Address<span className="text-red-500">*</span>
                                    </label>
                                    <textarea id="address" name="address" className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" rows="5" cols="50" onChange={guestFormik.handleChange} onBlur={guestFormik.handleBlur} value={guestFormik.values.address} />
                                    {guestFormik.touched.address && guestFormik.errors.address ? (
                                        <div className="text-red-500">{guestFormik.errors.address}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="w-full lg:w-12/12 px-4">
                                <div className="relative w-full mb-3">
                                    <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" >
                                        Purpose of visit
                                    </label>
                                    <select label="Purpose" id="purposeOfVisit" name="purposeOfVisit" color="blue" className="bg-white text-gray-900 border border-gray-300 rounded-md py-2 pl-3 pr-10 focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500" labelProps={{ hidden: true }}
                                        onChange={guestFormik.handleChange} onBlur={guestFormik.handleBlur} value={guestFormik.values.purposeOfVisit}>
                                        <option value="personal">Personal</option>
                                        <option value="company">Work</option>
                                    </select>
                                </div>
                            </div>
                            <div className="w-full lg:w-6/12 px-4">
                                <div className="relative w-full mb-3">
                                    <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" >
                                        GST Number
                                    </label>
                                    <input type="text" id="companyGST" name="companyGST" className="uppercase border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        onChange={guestFormik.handleChange} onBlur={guestFormik.handleBlur} value={guestFormik.values.companyGST} />
                                </div>
                            </div>
                            <div className="w-full lg:w-6/12 px-4">
                                <div className="relative w-full mb-3">
                                    <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" >
                                        Company Details
                                    </label>
                                    <input type="text" id="companyDetails" name="companyDetails" className="uppercase border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        onChange={guestFormik.handleChange} onBlur={guestFormik.handleBlur} value={guestFormik.values.companyDetails} />
                                </div>
                            </div>
                        </div>

                        <hr className="mt-6 border-b-1 border-blueGray-300" />

                        <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                            Identification Documents
                        </h6>
                        <div className="flex flex-wrap">
                            <div className="w-full lg:w-6/12 px-4">
                                <div className="relative w-full mb-3">
                                    <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                                        Document Type<span className="text-red-500">*</span>
                                    </label>
                                    <select color="blue" id="documentType" name="documentType" label="Document Type" className="bg-white text-gray-900 border border-gray-300 rounded-md py-2 pl-3 pr-10 focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                                        onChange={guestFormik.handleChange} onBlur={guestFormik.handleBlur} value={guestFormik.values.documentType}>
                                        <option value={""}>Select</option>
                                        <option value="Aadhaar Card">Aadhaar Card</option>
                                        <option value="Driving License">Driving License</option>
                                        <option value="Voter ID">Voter ID</option>
                                        <option value="Passport">Passport</option>
                                    </select>
                                    {guestFormik.touched.documentType && guestFormik.errors.documentType ? (
                                        <div className="text-red-500">{guestFormik.errors.documentType}</div>
                                    ) : null}
                                </div>
                                <div className="relative w-full mb-3">
                                    <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                                        Document Number<span className="text-red-500">*</span>
                                    </label>
                                    <input type="text" id="documentNumber" name="documentNumber" label="Document Type" className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        onChange={guestFormik.handleChange} onBlur={guestFormik.handleBlur} value={guestFormik.values.documentNumber} />

                                    {guestFormik.touched.documentNumber && guestFormik.errors.documentNumber ? (
                                        <div className="text-red-500">{guestFormik.errors.documentNumber}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="w-full lg:w-6/12 px-4">
                                <div className="relative w-full mb-3">
                                    <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                                        Upload
                                    </label>
                                    <div className="py-3">
                                        <input type="file" accept=".jpg,.jpeg,.png" onChange={handleFileChange} ref={fileInputRef} />
                                    </div>
                                </div>
                                <div className="relative w-full mb-3">
                                    <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" >
                                        Proofs<span className="text-red-500">*</span>
                                    </label>
                                    {
                                        guestFormik.values.attachments.map((image, i) =>
                                            <div className="flex flex-row">
                                                <img key={i} src={URL.createObjectURL(image)} style={{ height: "100px", width: "100px", margin: "5px" }} />
                                                <IconButton variant="text" onClick={() => handleDeleteAttachment(i)}>
                                                    <MdDelete className="h-6 w-6" />
                                                </IconButton>
                                            </div>
                                        )
                                    }
                                    <ul>
                                        {guestFormik.values.documentS3Ids.map((s3Id, i) => <li className="cursor-pointer"><u key={i} onClick={() => handleProofView(s3Id)}>{guestFormik.values.documentType} {i + 1}</u></li>)}
                                    </ul>
                                    {guestFormik.touched.documentS3Ids && guestFormik.errors.documentS3Ids ? (
                                        <div className="text-red-500">{guestFormik.errors.documentS3Ids}</div>
                                    ) : null}
                                </div>
                                <button className="shadow bg-blue-500 hover:bg-blue-700 focus:shadow-outline focus:outline-none text-white font-bold text-xs py-2 px-4  mr-2 rounded" type="button"
                                    onClick={handleUploadAttachments}>
                                    Upload
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="w-full flex justify-end  mb-0 px-6 py-6">
                        {!guestFormik.values.showCheckIn && <button className="bg-pink-500 text-white hover:bg-pink-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-2 ease-linear transition-all duration-150" type="button" onClick={handleSubmitInternal}>
                            Save
                        </button>}
                        {guestFormik.values.showCheckIn && <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-2 ease-linear transition-all duration-150" type="button" onClick={() => navigate("/manager/checkin", { state: { guestId: guestFormik.values.mobile, guestName: guestFormik.values.name } })}>
                            CheckIn
                        </button>}
                        {guestFormik.values.showCheckIn && <button className="bg-pink-500 text-white hover:bg-pink-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-2 ease-linear transition-all duration-150" type="button" onClick={handleSubmitInternal}>
                            Update
                        </button>}
                    </div>
                </div>
            </div>
            <Dialog open={isLoading} className="bg-transparent shadow-none" size="md">
                <DialogBody className="bg-transparent shadow-none flex justify-center">
                    <ClockLoader color="#2be60d" />
                </DialogBody>
            </Dialog>
            <Dialog size={"xs"} open={isShowProofImage}>
                <DialogHeader className="flex justify-end items-center h-full">
                    <Button
                        variant="text"
                        color="red"
                        onClick={() => setShowProofImage(false)}
                        className="mr-1"
                    >
                        <span>Close</span>
                    </Button>
                </DialogHeader>
                <DialogBody>
                    <img src={imageUrl} />
                </DialogBody>
                <DialogFooter>
                    <Button
                        variant="text"
                        color="red"
                        onClick={() => setShowProofImage(false)}
                        className="mr-1"
                    >
                        <span>Close</span>
                    </Button>
                </DialogFooter>
            </Dialog>
        </section>
    )
}
