import {
    Button,
    Dialog,
    DialogBody,
    DialogFooter,
    DialogHeader
} from "@material-tailwind/react";
import { useFormik } from "formik";
import React from 'react';
import * as Yup from 'yup';

export default function RoomPopup({ initialValues, isOpen, onClose, onSubmit, roomTypes }) {

    let roomFormik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            roomNo: Yup.string().required('Room No is required')
        }),
        onSubmit: async (values, actions) => {
            await onSubmit(values);
        }
    });

    let handleSubmitInternal = () => {
        roomFormik.handleSubmit();
    };

    let onCloseInternal = () => {
        roomFormik.resetForm();
        onClose();
    };

    const handleCategoryChange = (event) => {
        roomFormik.handleChange(event);
        const { name, value } = event.target;
        const roomType = roomTypes.find(rt => rt.category === value);
        roomFormik.setFieldValue('price', roomType.price);
        roomFormik.setFieldValue('gst', roomType.gst);
        roomFormik.setFieldValue('extraBedCharges', roomType.extraBedCharges);
        roomFormik.setFieldValue('capacity_adults', roomType.capacity.adults);
        roomFormik.setFieldValue('capacity_kids', roomType.capacity.kids);
    };


    return (
        <Dialog open={isOpen}>
            <form>
                <DialogHeader>Add Room</DialogHeader>
                <DialogBody>
                    <div className="grid grid-cols-2 gap-4">
                        <div className="flex flex-col">
                            <label for="roomNo" className="block uppercase text-blueGray-600 text-xs font-bold mb-2">Room No</label>
                            <input type="text" id="roomNo" name="roomNo" className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                onChange={roomFormik.handleChange} onBlur={roomFormik.handleBlur}
                                value={roomFormik.values.roomNo} />
                            {roomFormik.touched.category && roomFormik.errors.roomNo ? (
                                <div className="text-red-500">{roomFormik.errors.roomNo}</div>
                            ) : null}
                        </div>
                    </div>
                </DialogBody>
                <DialogFooter>
                    <Button
                        variant="text"
                        color="red"
                        onClick={onCloseInternal}
                        className="mr-1"
                    >
                        <span>Cancel</span>
                    </Button>
                    <Button variant="gradient" color="green" onClick={handleSubmitInternal}>
                        <span>Save</span>
                    </Button>
                </DialogFooter>
            </form>
        </Dialog>
    )
}
