import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Dialog, DialogBody,
    DialogFooter,
    DialogHeader,
    IconButton,
    Tooltip,
    Typography
} from "@material-tailwind/react";
import React, { useEffect, useState } from 'react';
import { HiPencil } from "react-icons/hi";
import { MdDelete } from "react-icons/md";
import { useNavigate } from 'react-router-dom';
import { ClockLoader } from 'react-spinners';
import { useUserContext } from '../../context';
import { useApi } from '../../hooks/useApi';

const TABLE_HEAD = ["S.No", "Date", "Type", "Amount", "Proofs", "Remarks", "Actions"];

export default function Expense() {

    const { fetchAccessToken, defaultHotel, user, isAdmin } = useUserContext();
    const [expenses, setExpenses] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState("");
    const [isShowProofImage, setShowProofImage] = useState(false);
    const { getExpenses, getImageUrl, deleteExpense } = useApi();
    const navigate = useNavigate();
    const [startDate, setStartDate] = useState(() => getCurrentMonthFirstDay());
    const [startTime, setStartTime] = useState("00:00");
    const [endDate, setEndDate] = useState(() => formatDate(0));
    const [endTime, setEndTime] = useState(() => getCurrentTime(0));
    const [filterType, setFilterType] = useState("All");
    const [filterUser, setFilterUser] = useState("All");
    const [users, setUsers] = useState([]);

    function getCurrentMonthFirstDay() {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        return `${year}-${month}-01`;
    }

    function formatDate(daysAdd = 0) {
        const date = new Date();
        date.setDate(date.getDate() + daysAdd);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${year}-${month}-${day}`;
    };

    function getCurrentTime(hoursAdd = 0) {
        const now = new Date();
        const hours = String(now.getHours() + hoursAdd).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        return hours + ':' + minutes;
    }

    let handleProofView = async (documentS3Id) => {
        try {
            setIsLoading(true);
            const token = await fetchAccessToken();
            const imageUrl = await getImageUrl(documentS3Id, token);
            if (imageUrl) {
                setImageUrl(imageUrl);
                setShowProofImage(true);
            }
        } catch (ex) {
            alert("Error occurred while viewing the Proof.");
        } finally {
            setIsLoading(false);
        }
    };

    function getEpoch(date, time, seconds = 0) {
        const [hours, minutes] = time.split(':').map(Number);
        const combinedDate = new Date(date);
        combinedDate.setHours(hours);
        combinedDate.setMinutes(minutes);
        combinedDate.setSeconds(seconds);
        combinedDate.setMilliseconds(seconds * 100);

        return combinedDate.getTime();
    }

    useEffect(() => {
        let getExpensesInternal = async (hotelId) => {
            setIsLoading(true);
            try {
                const token = await fetchAccessToken();
                const start = getEpoch(startDate, startTime);
                const end = getEpoch(endDate, endTime);
                const data = await getExpenses(hotelId, start, end, token);
                if (data && data.length) {
                    setFilterType("All");
                    setFilterUser("All");
                    setExpenses(data);
                    setUsers([...new Set(data.map(expense => expense.user))]);
                } else {
                    setFilterType("All");
                    setFilterUser("All");
                    setExpenses([]);
                    setUsers([]);
                }
            } catch (ex) {

            } finally {
                setIsLoading(false);
            }
        }
        if (defaultHotel) {
            getExpensesInternal(defaultHotel);
        }
    }, [defaultHotel]);

    const generateReport = async () => {
        if (!defaultHotel) {
            return;
        }
        setIsLoading(true);
        try {
            const token = await fetchAccessToken();
            const start = getEpoch(startDate, startTime);
            const end = getEpoch(endDate, endTime);
            const data = await getExpenses(defaultHotel, start, end, token);
            if (data && data.length) {
                setExpenses(data);
                setUsers([...new Set(data.map(expense => expense.user))]);
            } else {
                setFilterType("All");
                setFilterUser("All");
                setExpenses([]);
                setUsers([]);
            }
        } catch (ex) {

        } finally {
            setIsLoading(false);
        }
    };

    let handleEditExpense = async function (expenseData) {
        if (expenseData && defaultHotel) {
            navigate("/manager/expenses/edit", { state: { ...expenseData } });
        }
    }

    let handleDeleteExpense = async function (expenseDateTime) {
        const result = window.confirm("You are about to delete the Expense?");
        if (result) {
            if (defaultHotel) {
                setIsLoading(true);
                try {
                    const token = await fetchAccessToken();
                    await deleteExpense(defaultHotel, expenseDateTime, token);
                    alert("Expense deleted successfully.");
                    window.location.reload();
                } catch (ex) {

                } finally {
                    setIsLoading(false);
                }
            }
        }
    }

    const filteredExpenses = expenses
        .filter(expense => filterType === "All" || expense.expenseType === filterType)
        .filter(expense => isAdmin || (expense.user?.toLowerCase() === user.name.toLowerCase() || expense.expenseType === "Cash Collect"))
        .filter(expense => filterUser === "All" || expense.user === filterUser);

    function getTotalExpenses(expenses) {
        return expenses.reduce((total, expense) => total + expense.expenseAmount, 0);
    }


    return (
        <div>
            <Card className="h-full w-full">
                <CardHeader variant="gradient" color="gray" className="mt-6 mb-8 p-6 flex flex-row justify-between">
                    <Typography variant="h6" color="white">
                        Expenses
                    </Typography>
                    <button className="shadow bg-blue-500 hover:bg-blue-700 focus:shadow-outline focus:outline-none text-white font-bold text-xs py-2 px-4 rounded" type="button"
                        onClick={() => navigate("/manager/expenses/add", { state: {} })} >
                        Add Expense
                    </button>
                </CardHeader>
                <CardBody className="overflow-scroll px-0 pt-0 pb-2">
                    <div className="flex flex-wrap">
                        <div className="w-full lg:w-4/12 px-4">
                            <div className="h-10 flex justify-between sm:justify-start">
                                <Typography className="mt-2">
                                    Start:
                                </Typography>
                                <div className="flex flex-row">
                                    <input type="date" className="border-0 ml-2 mr-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        value={startDate} onChange={(event) => setStartDate(event.target.value)} />
                                    <input type="time" className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        value={startTime} onChange={(event) => setStartTime(event.target.value)} />
                                </div>
                            </div>
                        </div>
                        <div className="w-full lg:w-4/12 px-4">
                            <div className="h-10 flex justify-between sm:justify-start">
                                <Typography className="mt-2">
                                    End:
                                </Typography>
                                <div className="flex flex-row">
                                    <input type="date" className="border-0  ml-2 mr-2  px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        value={endDate} onChange={(event) => setEndDate(event.target.value)} />
                                    <input type="time" className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        value={endTime} onChange={(event) => setEndTime(event.target.value)} />
                                </div>
                            </div>
                        </div>
                        <div className="w-full sm:w-4/12 p-1">
                            <div className="h-10 flex justify-end">
                                <button className="bg-pink-500 text-white hover:bg-pink-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-2 ease-linear transition-all duration-150"
                                    type="button" onClick={generateReport}>
                                    Run
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="px-4 flex flex-wrap">
                        <div className="w-full lg:w-4/12 px-4">
                            <div className="h-10 flex justify-between sm:justify-start">
                                <Typography className="mt-2">
                                    Filter:
                                </Typography>
                                <select label="filterType" color="blue" className="ml-4 mb-1 bg-white text-gray-900 border border-gray-300 rounded-md py-2 pl-3 pr-10 focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                                    name="filterType" id="filterType"
                                    onChange={(event) => setFilterType(event.target.value)}
                                    value={filterType}>
                                    <option key="All" value="All">All</option>
                                    <option key="Electricity" value="Electricity">Electricity</option>
                                    <option key="Rent" value="Rent">Rent</option>
                                    <option key="Cash Collect" value="Cash Collect">Cash Collect</option>
                                    <option key="Salaries" value="Salaries">Salaries</option>
                                    <option key="Recharge" value="Recharge">Recharge</option>
                                    <option key="Repairs" value="Repairs">Repairs</option>
                                    <option key="Food" value="Food">Food</option>
                                    <option key="Curries" value="Curries">Curries</option>
                                    <option key="Material" value="Material">Material</option>
                                    <option key="HK Material" value="HK Material">HK Material</option>
                                    <option key="Dhobi" value="Dhobi">Dhobi</option>
                                    <option key="Misc Expenses" value="Misc Expenses">Misc Expenses</option>
                                </select>
                                <select label="filterUser" color="blue" className="ml-4 mb-1 bg-white text-gray-900 border border-gray-300 rounded-md py-2 pl-3 pr-10 focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                                    name="filterType" id="filterType"
                                    onChange={(event) => setFilterUser(event.target.value)}
                                    value={filterUser}>
                                    <option key="All" value="All">All</option>
                                    {users.map(user => <option key={user} value={user}>{user}</option>)}
                                </select>
                            </div>
                        </div>
                        <div className="w-full lg:w-4/12 px-4">
                            <div className="h-10 flex justify-between sm:justify-start">
                                <Typography className="mt-2 ml-4">
                                    Total:
                                </Typography>
                                <Typography className="mt-2 ml-4">
                                    {getTotalExpenses(filteredExpenses)}.00
                                </Typography>
                            </div>
                        </div>
                    </div>
                    <table className="mt-0 w-full min-w-max table-auto text-left">
                        <thead>
                            <tr>
                                {TABLE_HEAD.map((head) => (
                                    <th
                                        key={head}
                                        className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
                                    >
                                        <Typography
                                            variant="small"
                                            color="blue-gray"
                                            className="font-normal leading-none opacity-70"
                                        >
                                            {head}
                                        </Typography>
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {filteredExpenses.map(
                                ({ expenseType, expenseAmount, expenseDate, documentS3Ids, remarks, expenseDateTime, user, cash }, index) => {
                                    const isLast = index === expenses.length - 1;
                                    const classes = isLast
                                        ? "p-2"
                                        : "p-2 border-b border-blue-gray-50";
                                    return (
                                        <tr key={index}>
                                            <td className={classes}>
                                                <Typography
                                                    variant="small"
                                                    color="blue-gray"
                                                    className="font-normal"
                                                >
                                                    {index + 1}
                                                </Typography>
                                            </td>
                                            <td className={classes}>
                                                <Typography
                                                    variant="small"
                                                    color="blue-gray"
                                                    className="font-normal"
                                                >
                                                    {expenseDate}
                                                </Typography>
                                            </td>
                                            <td className={classes}>
                                                <div>
                                                    <Typography
                                                        variant="small"
                                                        color="blue-gray"
                                                        className="font-normal"
                                                    >
                                                        {expenseType}
                                                    </Typography>
                                                </div>
                                            </td>
                                            <td className={classes}>
                                                <div>
                                                    <Typography
                                                        variant="small"
                                                        color="blue-gray"
                                                        className="font-normal"
                                                    >
                                                        {expenseAmount}.00
                                                    </Typography>
                                                </div>
                                            </td>
                                            <td className={classes}>
                                                <div className="flex flex-col">
                                                    <Typography
                                                        variant="small"
                                                        color="blue-gray"
                                                        className="font-normal"
                                                    >
                                                        {documentS3Ids.map((s3Id, i) => (<li className="cursor-pointer"><u key={i} onClick={() => handleProofView(s3Id)}>{"Proof "} {i + 1}</u></li>))}
                                                    </Typography>
                                                </div>
                                            </td>
                                            <td className={classes}>
                                                <div>
                                                    <Typography
                                                        variant="small"
                                                        color="blue-gray"
                                                        className="font-normal"
                                                    >
                                                        {remarks}
                                                    </Typography>
                                                </div>
                                            </td>
                                            <td className={classes}>
                                                {isAdmin && <Tooltip content="Edit">
                                                    <IconButton variant="text" onClick={() => handleEditExpense({ expenseType, expenseAmount, expenseDate, expenseRemarks: remarks, expenseDateTime, documentS3Ids, cratedBy: user, cash })}>
                                                        <HiPencil className="h-6 w-6" />
                                                    </IconButton>
                                                </Tooltip>}
                                                {isAdmin &&
                                                    <Tooltip content="Delete">
                                                        <IconButton variant="text" onClick={() => handleDeleteExpense(expenseDateTime)}>
                                                            <MdDelete className="h-6 w-6" />
                                                        </IconButton>
                                                    </Tooltip>
                                                }

                                            </td>
                                        </tr>
                                    );
                                },
                            )}
                        </tbody>
                    </table>
                </CardBody >
            </Card >

            <Dialog size={"xs"} open={isShowProofImage}>
                <DialogHeader className="flex justify-end items-center h-full">
                    <Button
                        variant="text"
                        color="red"
                        onClick={() => setShowProofImage(false)}
                        className="mr-1"
                    >
                        <span>Close</span>
                    </Button>
                </DialogHeader>
                <DialogBody>
                    <img src={imageUrl} />
                </DialogBody>
                <DialogFooter>
                    <Button
                        variant="text"
                        color="red"
                        onClick={() => setShowProofImage(false)}
                        className="mr-1"
                    >
                        <span>Close</span>
                    </Button>
                </DialogFooter>
            </Dialog>
            <Dialog open={isLoading} className="bg-transparent shadow-none" size="md">
                <DialogBody className="bg-transparent shadow-none flex justify-center">
                    <ClockLoader color="#2be60d" />
                </DialogBody>
            </Dialog>
        </div >
    )
}
