import { Typography, Dialog, DialogBody } from '@material-tailwind/react';
import React, { useState } from 'react';
import { useApi } from '../../hooks/useApi';
import { useUserContext } from '../../context';
import { ClockLoader } from 'react-spinners';

export default function Downloads() {
    const [isLoading, setIsLoading] = useState(false);
    const [startDate, setStartDate] = useState(getFirstDayOfLastMonth());
    const [endDate, setEndDate] = useState(getLastDayOfLastMonth());
    const [reportType, setReportType] = useState("");
    const { downloadCards } = useApi();
    const { fetchAccessToken, defaultHotel } = useUserContext();

    function getFirstDayOfLastMonth() {
        const now = new Date();
        const firstDayCurrentMonth = new Date(now.getFullYear(), now.getMonth(), 1);
        const lastMonth = new Date(firstDayCurrentMonth);
        lastMonth.setMonth(firstDayCurrentMonth.getMonth() - 1);
        const year = lastMonth.getFullYear();
        const month = String(lastMonth.getMonth() + 1).padStart(2, '0');
        return `${year}-${month}-01`;
    }

    function getLastDayOfLastMonth() {
        const now = new Date();
        const firstDayCurrentMonth = new Date(now.getFullYear(), now.getMonth(), 1);

        const lastDayLastMonth = new Date(firstDayCurrentMonth);
        lastDayLastMonth.setDate(firstDayCurrentMonth.getDate() - 1);

        const year = lastDayLastMonth.getFullYear();
        const month = String(lastDayLastMonth.getMonth() + 1).padStart(2, '0');
        const day = String(lastDayLastMonth.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    function getEpoch(date, time, seconds = 0) {
        const [hours, minutes] = time.split(':').map(Number);
        const combinedDate = new Date(date);
        combinedDate.setHours(hours);
        combinedDate.setMinutes(minutes);
        combinedDate.setSeconds(seconds);
        combinedDate.setMilliseconds(seconds * 100);

        return combinedDate.getTime();
    }

    const downloadReport = async () => {
        if (!defaultHotel || !reportType) {
            return;
        }
        try {
            setIsLoading(true);
            const start = getEpoch(startDate, "00:00");
            const end = getEpoch(endDate, "23:59");
            const token = await fetchAccessToken();
            switch (reportType) {
                case "Cards":
                    await downloadCards(defaultHotel, start, end, token)
                    break;

                default:
                    alert("TODO");
                    break;
            }
        } catch (ex) {
            alert("Something went wrong.")
        } finally {
            setIsLoading(false);
        }

    };

    return (
        <div>
            <div className="relative flex flex-col min-w-0 break-words w-full pt-3 mb-6 shadow-lg rounded-lg bg-blueGray-100 border border-blue-gray-100 border-1">
                <div className="flex flex-wrap">
                    <div className="w-full lg:w-3/12 px-4">
                        <div className="h-10 flex justify-between sm:justify-start">
                            <Typography className="mt-2">
                                Start:
                            </Typography>
                            <div className="flex flex-row">
                                <input type="date" className="border-0 ml-2 mr-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    value={startDate} onChange={(event) => setStartDate(event.target.value)} />
                            </div>
                        </div>
                    </div>
                    <div className="w-full lg:w-3/12 px-4">
                        <div className="h-10 flex justify-between sm:justify-start">
                            <Typography className="mt-2">
                                End:
                            </Typography>
                            <div className="flex flex-row">
                                <input type="date" className="border-0  ml-2 mr-2  px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    value={endDate} onChange={(event) => setEndDate(event.target.value)} />
                            </div>
                        </div>
                    </div>
                    <div className="w-full lg:w-3/12 px-4">
                        <div className="h-10 flex justify-between sm:justify-start">
                            <Typography className="mt-2">
                                Type:
                            </Typography>
                            <select label="reportType" color="blue" className="ml-4 bg-white text-gray-900 border border-gray-300 rounded-md py-2 pl-3 pr-10 focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                                name="reportType" id="reportType"
                                onChange={(event) => setReportType(event.target.value)}
                                value={reportType}>
                                <option key="" value="">Select</option>
                                <option key="Cards" value="Cards">Card Report</option>
                                <option key="GST" value="GST">GST Invoices</option>
                                <option key="Expense" value="Expense">Expense Report</option>
                                <option key="Occupancy" value="Occupancy">Occupancy Report</option>
                            </select>
                        </div>
                    </div>
                    <div className="w-full lg:w-3/12 px-4">
                        <div className=" flex justify-end">
                            <button className="bg-pink-500 text-white hover:bg-pink-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-2 ease-linear transition-all duration-150"
                                type="button" onClick={downloadReport}>
                                Download
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <Dialog open={isLoading} className="bg-transparent shadow-none" size="md">
                <DialogBody className="bg-transparent shadow-none flex justify-center">
                    <ClockLoader color="#2be60d" />
                </DialogBody>
            </Dialog>
        </div >
    );
}