import { Typography, Dialog, DialogBody } from '@material-tailwind/react';
import React, { useEffect, useRef, useState } from 'react';
import DatePicker from '../../components/date-picker';
import SingleSelectChip from '../../components/single-select-chip';
import TimePicker from '../../components/time-picker';
import { ClockLoader } from 'react-spinners';
import { useReactToPrint } from 'react-to-print';
import { useUserContext } from '../../context';
import AmountCard from '../../widgets/cards/amount-card';
import { useApi } from '../../hooks/useApi';

const paymentOptions = [
    { label: 'All', value: 'all' },
    { label: 'UPI', value: 'upi' },
    { label: 'Cash', value: 'cash' },
    { label: 'Card', value: 'card' }
];

const TABLE_HEAD_REPORT = ["S#", "Card#", "Amount", "Remarks"];

export default function Reports() {
    const [startDate, setStartDate] = useState(getFirstDayOfLastMonth());
    const [startTime, setStartTime] = useState("00:00");
    const [endDate, setEndDate] = useState(getLastDayOfLastMonth());
    const [endTime, setEndTime] = useState("23:59");
    const [isLoading, setIsLoading] = useState(false);
    const { fetchAccessToken, defaultHotel } = useUserContext();
    const { getPayments, getExpenses, getIncomes } = useApi();
    const [totalPayments, setTotalPayments] = useState([]);
    const [filteredPayments, setFilteredPayments] = useState([]);
    const [selectedOption, setSelectedOption] = useState({});
    const [expenses, setExpenses] = useState([]);
    const [incomes, setIncomes] = useState([]);
    const handleOptionChange = (option) => {
        setSelectedOption(option);
    };

    useEffect(() => {
        let filteredData = [...totalPayments];
        switch (selectedOption.value) {
            case 'upi':
                filteredData = totalPayments.map(item => {
                    const upiPayments = item.payments.filter(payment => payment.paymentMode === "UPI");
                    return { ...item, payments: upiPayments };
                }).filter(item => item.payments.length > 0);
                break;
            case 'cash':
                filteredData = totalPayments.map(item => {
                    const cashPayments = item.payments.filter(payment => payment.paymentMode === "Cash");
                    return { ...item, payments: cashPayments };
                }).filter(item => item.payments.length > 0);;
                break;
            case 'online':
                filteredData = totalPayments.map(item => {
                    const onlinePayments = item.payments.filter(payment => payment.paymentMode === "Online Booking");
                    return { ...item, payments: onlinePayments };
                }).filter(item => item.payments.length > 0);;
                break;
            case 'account':
                filteredData = totalPayments.map(item => {
                    const onlinePayments = item.payments.filter(payment => payment.paymentMode === "Company Bill");
                    return { ...item, payments: onlinePayments };
                }).filter(item => item.payments.length > 0);;
                break;
            case 'card':
                filteredData = totalPayments.map(item => {
                    const cardPayments = item.payments.filter(payment => payment.paymentMode === "Card");
                    return { ...item, payments: cardPayments };
                }).filter(item => item.payments.length > 0);;
                break;
            default:
                filteredData = [...totalPayments];
                break;
        }
        setFilteredPayments(filteredData);
    }, [selectedOption]);

    //TODO: Online Payments

    const handleGenerateReport = async () => {
        if (!defaultHotel) {
            return;
        }
        try {
            setIsLoading(true);
            const start = getEpoch(startDate, startTime);
            const end = getEpoch(endDate, endTime);
            const token = await fetchAccessToken();
            const paymentsData = await getPayments(defaultHotel, start, end, token);
            const groupedPayments = paymentsData.reduce((acc, paymentAudit) => {
                const { cardNo, paymentMode, paymentAmount, paymentDateTime } = paymentAudit;
                const existingCard = acc.find(item => item.cardNo === cardNo);
                if (existingCard) {
                    existingCard.payments.push({ paymentMode, paymentAmount, paymentDateTime });
                } else {
                    acc.push({ cardNo, payments: [{ paymentMode, paymentAmount, paymentDateTime }], remarks: "" });
                }
                return acc;
            }, []);
            const expensesData = await getExpenses(defaultHotel, start, end, token);
            const incomesData = await getIncomes(defaultHotel, start, end, token);
            setExpenses(expensesData);
            setIncomes(incomesData);
            if (incomesData && incomesData.length > 0) {
                incomesData.map(item => {
                    groupedPayments.push({
                        cardNo: arrayToCommaSeparatedString(item.cardNos),
                        payments: [{
                            paymentMode: item.incomeType,
                            paymentAmount: item.incomeAmount, paymentDateTime: item.incomeDateTime
                        }],
                        remarks: item.remarks
                    });
                });
            }
            setTotalPayments(groupedPayments);
            setSelectedOption(paymentOptions[0]);
            setFilteredPayments(groupedPayments);
        } catch (ex) {
            alert("Something went wrong.")
        } finally {
            setIsLoading(false);
        }

    };

    function arrayToCommaSeparatedString(numbersArray) {
        if (Array.isArray(numbersArray) && numbersArray.length > 0) {
            return numbersArray.join(', ');
        } else {
            return "NA"
        }
    }

    function getEpoch(date, time, seconds = 0) {
        const [hours, minutes] = time.split(':').map(Number);
        const combinedDate = new Date(date);
        combinedDate.setHours(hours);
        combinedDate.setMinutes(minutes);
        combinedDate.setSeconds(seconds);
        combinedDate.setMilliseconds(seconds * 100);

        return combinedDate.getTime();
    }

    const contentToPrint = useRef(null);

    const handlePrint = useReactToPrint({
        documentTitle: "Report for G Hotel",
        onBeforePrint: () => console.log("before printing..."),
        onAfterPrint: () => console.log("after printing..."),
        removeAfterPrint: true,
    });

    const printReports = () => {

        handlePrint(null, () => contentToPrint.current);
    }


    function getFirstDayOfLastMonth() {
        const now = new Date();
        const firstDayCurrentMonth = new Date(now.getFullYear(), now.getMonth(), 1);
        const lastMonth = new Date(firstDayCurrentMonth);
        lastMonth.setMonth(firstDayCurrentMonth.getMonth() - 1);
        return lastMonth;
    }

    function getLastDayOfLastMonth() {
        const now = new Date();
        const firstDayCurrentMonth = new Date(now.getFullYear(), now.getMonth(), 1);

        const lastDayLastMonth = new Date(firstDayCurrentMonth);
        lastDayLastMonth.setDate(firstDayCurrentMonth.getDate() - 1);

        return lastDayLastMonth;
    }

    function getTotalAmount(data) {
        let totalPayment = data.reduce((acc, obj) => {
            let sum = obj.payments.reduce((subtotal, payment) => {
                return subtotal + payment.paymentAmount;
            }, 0);
            return acc + sum;
        }, 0);
        return totalPayment;
    }

    function getTotalCashAmount() {
        let data = totalPayments.map(item => {
            const cashPayments = item.payments.filter(payment => payment.paymentMode === "Cash");
            return { ...item, payments: cashPayments };
        });
        let totalPayment = data.reduce((acc, obj) => {
            let sum = obj.payments.reduce((subtotal, payment) => {
                return subtotal + payment.paymentAmount;
            }, 0);
            return acc + sum;
        }, 0);
        return totalPayment;
    }

    function getTotalUpiAmount() {
        let data = totalPayments.map(item => {
            const upiPayments = item.payments.filter(payment => payment.paymentMode === "UPI");
            return { ...item, payments: upiPayments };
        });
        let totalPayment = data.reduce((acc, obj) => {
            let sum = obj.payments.reduce((subtotal, payment) => {
                return subtotal + payment.paymentAmount;
            }, 0);
            return acc + sum;
        }, 0);
        return totalPayment;
    }

    function getTotalCardAmount() {
        let data = totalPayments.map(item => {
            const cardPayments = item.payments.filter(payment => payment.paymentMode === "Card");
            return { ...item, payments: cardPayments };
        });
        let totalPayment = data.reduce((acc, obj) => {
            let sum = obj.payments.reduce((subtotal, payment) => {
                return subtotal + payment.paymentAmount;
            }, 0);
            return acc + sum;
        }, 0);
        return totalPayment;
    }

    function getTotalExpenses() {
        return expenses.filter(item => item.expenseType !== "Cash Collect").reduce((total, expense) => total + expense.expenseAmount, 0);
    }

    function getTotalIncome(incomeType) {
        return incomes.filter(item => item.incomeType.toLowerCase() === incomeType).reduce((total, income) => total + income.incomeAmount, 0);
    }

    const sortedFilteredPayments = filteredPayments.sort((a, b) => a.cardNo - b.cardNo);

    return (

        <div className="relative flex flex-col min-w-0 break-words w-full pt-3 mb-6 shadow-lg rounded-lg bg-blueGray-100 border border-blue-gray-100 border-1">
            <div className="flex flex-wrap">
                <div className="w-full lg:w-4/12 px-4">
                    <div className="h-10 flex justify-between sm:justify-start">
                        <Typography className="mt-2">
                            Start:
                        </Typography>
                        <div className="flex flex-row">
                            <DatePicker defaultDate={startDate} onDateSelect={setStartDate} />
                            <TimePicker defaultTime={startTime} onTimeChange={setStartTime} />
                        </div>
                    </div>
                </div>
                <div className="w-full lg:w-4/12 px-4">
                    <div className="h-10 flex justify-between sm:justify-start">
                        <Typography className="mt-2">
                            End:
                        </Typography>
                        <div className="flex flex-row">
                            <DatePicker defaultDate={endDate} onDateSelect={setEndDate} />
                            <TimePicker defaultTime={endTime} onTimeChange={setEndTime} />
                        </div>
                    </div>
                </div>
                <div className="w-full sm:w-4/12 p-1">
                    <div className="h-10 flex justify-end">
                        <button className="bg-pink-500 text-white hover:bg-pink-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-2 ease-linear transition-all duration-150"
                            type="button" onClick={handleGenerateReport}>
                            Run
                        </button>
                    </div>
                </div>
            </div>
            <div className="flex flex-wrap">
                <div className="w-full p-2">
                    <div className="flex justify-between sm:justify-start">
                        <Typography className="mt-2">
                            Filter by:
                        </Typography>
                        <div className="w-200 ml-5">
                            <SingleSelectChip options={paymentOptions} onChange={handleOptionChange} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-wrap">
                <div className="w-full h-full p-1">
                    <div className="h-10 flex justify-end">
                        <button className="bg-pink-500 text-white hover:bg-pink-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-2 ease-linear transition-all duration-150" type="button"
                            onClick={printReports}>
                            Print
                        </button>
                    </div>
                </div>
            </div>
            <div className="flex flex-wrap overflow-scroll px-0 pt-0 pb-2">
                <table className="mt-0 w-full min-w-max table-auto text-left" ref={contentToPrint}>
                    <thead>
                        <tr>
                            {TABLE_HEAD_REPORT.map((head) => (
                                <th
                                    key={head}
                                    className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
                                >
                                    <Typography
                                        variant="small"
                                        color="blue-gray"
                                        className="font-normal leading-none opacity-90"
                                    >
                                        {head}
                                    </Typography>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {sortedFilteredPayments.map(
                            ({ cardNo, payments, remarks }, index) => {
                                const isLast = index === TABLE_HEAD_REPORT.length - 1;
                                const classes = isLast
                                    ? "p-4"
                                    : "p-4 border-b border-blue-gray-50";

                                return (
                                    <tr key={index}>
                                        <td className={classes}>
                                            <div>
                                                <Typography
                                                    variant="small"
                                                    color="blue-gray"
                                                    className="font-normal"
                                                >
                                                    {index + 1}
                                                </Typography>
                                            </div>
                                        </td>
                                        <td className={classes}>
                                            <div className="flex flex-col w-64">
                                                <Typography
                                                    variant="small"
                                                    color="blue-gray"
                                                    className="font-normal break-words"
                                                >
                                                    {cardNo}
                                                </Typography>
                                            </div>
                                        </td>
                                        <td className={classes}>
                                            <AmountCard payments={payments} />
                                        </td>
                                        <td className={classes}>
                                            <div className="flex flex-col">
                                                <Typography
                                                    variant="small"
                                                    color="blue-gray"
                                                    className="font-normal"
                                                >
                                                    {remarks}
                                                </Typography>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            },
                        )}
                    </tbody>
                </table>
                <table className="mt-0 w-full min-w-max table-auto text-left">
                    <tr className="bg-gray-50 border-t-2 border-gray-500">
                        <td className="px-6 py-4 whitespace-nowrap text-right">Cash</td>
                        <td className="px-6 py-4 whitespace-nowrap">{getTotalCashAmount(totalPayments)}.00</td>
                        <td></td>
                    </tr>
                    <tr className="bg-gray-50">
                        <td className="px-6 py-4 whitespace-nowrap text-right">UPI</td>
                        <td className="px-6 py-4 whitespace-nowrap">{getTotalUpiAmount(getTotalUpiAmount)}.00</td>
                        <td></td>
                    </tr>
                    <tr className="bg-gray-50">
                        <td className="px-6 py-4 whitespace-nowrap text-right">Card</td>
                        <td className="px-6 py-4 whitespace-nowrap">{getTotalCardAmount(totalPayments)}.00</td>
                        <td></td>
                    </tr>
                    <tr className="bg-gray-50">
                        <td className="px-6 py-4 whitespace-nowrap text-right">Online Booking</td>
                        <td className="px-6 py-4 whitespace-nowrap">{getTotalIncome("online payment")}.00</td>
                        <td></td>
                    </tr>
                    <tr className="bg-gray-50">
                        <td className="px-6 py-4 whitespace-nowrap text-right">Company Booking</td>
                        <td className="px-6 py-4 whitespace-nowrap">{getTotalIncome("company payment")}.00</td>
                        <td></td>
                    </tr>
                    <tr className="bg-gray-50">
                        <td className="px-6 py-4 whitespace-nowrap text-right">Past Dues</td>
                        <td className="px-6 py-4 whitespace-nowrap">{getTotalIncome("future payment")}.00</td>
                        <td></td>
                    </tr>
                    <tr className="bg-gray-50">
                        <td className="px-6 py-4 whitespace-nowrap text-right">Others</td>
                        <td className="px-6 py-4 whitespace-nowrap">{getTotalIncome("others")}.00</td>
                        <td></td>
                    </tr>
                    <tr className="bg-gray-50 border-t-2 border-gray-500">
                        <td colSpan={3}>
                        </td>
                    </tr>
                    <tr className="bg-gray-50">
                        <td className="px-6 py-4 whitespace-nowrap text-right">Total Income</td>
                        <td className="px-6 py-4 whitespace-nowrap">{getTotalAmount(totalPayments)}.00</td>
                        <td>
                            <div className="bg-gray-50 flex flex-row">
                                <div className="px-6 py-4 whitespace-nowrap text-right" colSpan={2} >Expenses</div>
                                <div className="px-6 py-4 whitespace-nowrap">{getTotalExpenses()}.00</div>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
            <Dialog open={isLoading} className="bg-transparent shadow-none" size="md">
                <DialogBody className="bg-transparent shadow-none flex justify-center">
                    <ClockLoader color="#2be60d" />
                </DialogBody>
            </Dialog>
        </div>
    )
}
