import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Dialog, DialogBody,
    DialogFooter,
    DialogHeader,
    Typography,
    Checkbox,
    Chip
} from "@material-tailwind/react";
import React, { useEffect, useState } from 'react';
import { useUserContext } from '../../context';
import { useApi } from '../../hooks/useApi';
import AmountCard from '../../widgets/cards/amount-card';
import { ClockLoader } from 'react-spinners';
import RoomBookingCard from '../../widgets/cards/room-booking-card';

import { Link, useNavigate } from 'react-router-dom';

const options = ["ACTIVE", "PENDING", "CLOSE", "FREE"];

const TABLE_HEAD = ["", "Card#", "Date", "Status", "Rooms", "Total Amount", "Amount Received", "Proofs", "Discount", "Due", "Due Reason"];

export default function Cards() {
    const { fetchAccessToken, defaultHotel, isAdmin } = useUserContext();
    const [isLoading, setIsLoading] = useState(false);
    const [cardsData, setCardsData] = useState([]);
    const [selectedOption, setSelectedOption] = useState(options[0]);
    const { getCards, getCardsByStatus, getImageUrl, saveIncome, markCardsClose } = useApi();
    const [imageUrl, setImageUrl] = useState("");
    const [isShowProofImage, setShowProofImage] = useState(false);
    const [selectedItems, setSelectedItems] = useState(new Set());
    const [isShowIncomePopup, setShowIncomePopup] = useState(false);
    const [type, setType] = useState("");
    const [remarks, setRemarks] = useState("");
    const [amount, setAmount] = useState(0);
    const formattedDate = formatDate();
    const [date, setDate] = useState(formattedDate);
    const [isOnlineChecked, setOnlineChecked] = useState(false);
    const [isWalkInChecked, setWalkInChecked] = useState(false);
    const [cardNo, setCardNo] = useState(0);
    const navigate = useNavigate();

    function formatDate(daysAdd = 0) {
        const date = new Date();
        date.setDate(date.getDate() + daysAdd);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${year}-${month}-${day}`;
    };

    const handleOptionChange = (option) => {
        setSelectedOption(option);
    };



    useEffect(() => {
        let getCardsInternal = async (hotelId) => {
            setIsLoading(true);
            try {
                const token = await fetchAccessToken();
                let cards = [];
                if (selectedOption === "CLOSE" || selectedOption === "FREE") {
                    cards = await getCards(hotelId, (Date.now() - 604800000), Date.now(), token);
                } else {
                    cards = await getCardsByStatus(hotelId, selectedOption, token);
                }
                if (cards && cards.length) {
                    setCardsData(cards);
                } else {
                    setCardsData([]);
                }
            } catch (ex) {

            } finally {
                setIsLoading(false);
            }
        }
        if (defaultHotel) {
            getCardsInternal(defaultHotel);
        }
    }, [defaultHotel, selectedOption]);

    let handleProofView = async (documentS3Id) => {
        try {
            setIsLoading(true);
            const token = await fetchAccessToken();
            const imageUrl = await getImageUrl(documentS3Id, token);
            if (imageUrl) {
                setImageUrl(imageUrl);
                setShowProofImage(true);
            }
        } catch (ex) {
            alert("Error occurred while viewing the Proof.");
        } finally {
            setIsLoading(false);
        }
    };

    const handleCheckboxChange = (cardNo) => {
        setSelectedItems(prevSelectedItems => {
            const newSelectedItems = new Set(prevSelectedItems);
            if (newSelectedItems.has(cardNo)) {
                newSelectedItems.delete(cardNo);
            } else {
                newSelectedItems.add(cardNo);
            }
            return newSelectedItems;
        });
    };

    const handleMarkCloseInternal = async () => {
        try {
            setShowIncomePopup(false);
            setIsLoading(true);
            let incomeDateTime = getEpoch(date);
            let incomeDate = getDateAndTime(date)
            const token = await fetchAccessToken();
            const income = {
                incomeType: type,
                incomeAmount: amount,
                incomeDate: incomeDate,
                incomeDateTime: incomeDateTime,
                remarks: remarks,
                cardNos: Array.from(selectedItems)
            };
            await saveIncome(defaultHotel, income, token);
            await markCardsClose(defaultHotel, Array.from(selectedItems), token);
            alert("Card(s) closed");
            window.location.reload();
        } catch (ex) {
            console.log(ex);
            alert("Error occurred while saving the income.");
        } finally {
            setIsLoading(false);
        }
    };

    function getEpoch(date) {
        const now = new Date();
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const seconds = String(now.getSeconds()).padStart(2, '0');
        const combinedDate = new Date(date);
        combinedDate.setHours(hours);
        combinedDate.setMinutes(minutes);
        combinedDate.setSeconds(seconds);
        combinedDate.setMilliseconds(seconds * 100);

        return combinedDate.getTime();
    }

    function getDateAndTime(date) {
        const now = new Date();
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const combinedDate = new Date(date);
        combinedDate.setHours(hours);
        combinedDate.setMinutes(minutes);

        const formattedDateTime = `${combinedDate.getDate().toString().padStart(2, '0')}-${(combinedDate.getMonth() + 1).toString().padStart(2, '0')}-${combinedDate.getFullYear()} ${combinedDate.getHours().toString().padStart(2, '0')}:${combinedDate.getMinutes().toString().padStart(2, '0')}`;
        return formattedDateTime;
    }

    const sortedCardData = [...cardsData].sort((a, b) => b.cardNo - a.cardNo);

    function searchCardNumber() {
        if (cardNo > 0) {
            navigate("/manager/cards/" + cardNo)
        }
    }

    return (
        <div>
            <Card className="h-full w-full">
                <CardHeader variant="gradient" color="gray" className="mt-6 mb-8 p-4">
                    <Typography variant="h6" color="white">
                        Cards
                    </Typography>
                </CardHeader>
                <CardBody className="overflow-scroll px-0 pt-0 pb-2">
                    <div className="flex flex-wrap overflow-x-auto">
                        {options.map((option) => (
                            <button
                                key={option}
                                onClick={() => handleOptionChange(option)}
                                className={`${selectedOption === option ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'
                                    } font-small rounded-full px-3 py-1 m-1 focus:outline-none border border-green-400`}>
                                {option}
                            </button>
                        ))}
                        {isAdmin && selectedOption === "PENDING" &&
                            <button className={`px-4 py-2 rounded text-white transition
                                        ${selectedItems.size === 0 ? 'bg-gray-400 cursor-not-allowed text-white font-bold ml-10 mb-1 px-3 rounded' : 'bg-blue-500 hover:bg-blue-700 text-white font-bold ml-10 mb-1 px-3 rounded'}`}
                                disabled={selectedItems.size === 0} onClick={() => setShowIncomePopup(!isShowIncomePopup)}>Mark Close</button>
                        }
                        {isAdmin && selectedOption === "PENDING" &&
                            <Checkbox checked={isOnlineChecked} onChange={() => setOnlineChecked(!isOnlineChecked)} className={`${selectedOption === "PENDING" ? 'block' : 'hidden'}`} label="Online" />
                        }
                        {isAdmin && selectedOption === "PENDING" &&
                            <Checkbox checked={isWalkInChecked} onChange={() => setWalkInChecked(!isWalkInChecked)} className={`${selectedOption === "PENDING" ? 'block' : 'hidden'}`} label="Walk-In" />
                        }
                        <Typography variant="h6" className="py-3 px-3">
                            Search card:
                        </Typography>
                        <input type="number" id="cardNo" name="cardNo"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
                            value={cardNo} onChange={(event) => setCardNo(parseInt(event.target.value, 10))} />
                        <Button className="rounded-full mt-2 ml-3" size="sm" onClick={searchCardNumber}>View</Button>

                    </div>
                    <table className="mt-0 w-full min-w-max table-auto text-left">
                        <thead>
                            <tr>
                                {TABLE_HEAD.map((head) => (
                                    <th
                                        key={head}
                                        className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
                                    >
                                        <Typography
                                            variant="small"
                                            color="blue-gray"
                                            className="font-normal leading-none opacity-70"
                                        >
                                            {head}
                                        </Typography>
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {sortedCardData.filter(card => card.status === selectedOption || card.bookingType === "Free")
                                .filter(card => selectedOption !== "PENDING" || (!isWalkInChecked && !isOnlineChecked) || (isWalkInChecked && card.bookingType === "Walk-In") || (isOnlineChecked && card.bookingType === "Online"))
                                .map(
                                    ({ cardNo, createdOn, totalAmount, guestName, guestMobile, paymentDetails, roomDetails, amountDue, discount, status, dueReason, documentS3Ids, bookingType, reference }, index) => {
                                        const isLast = index === cardsData.length - 1;
                                        const classes = isLast
                                            ? "p-2"
                                            : "p-2 border-b border-blue-gray-50";
                                        return (
                                            <tr key={cardNo}>
                                                <td className={classes}>
                                                    <div>
                                                        {isAdmin && <Checkbox className={`${selectedOption === "PENDING" ? 'block' : 'hidden'}`}
                                                            checked={selectedItems.has(cardNo)} onChange={() => handleCheckboxChange(cardNo)} />
                                                        }
                                                    </div>
                                                </td>
                                                <td className={classes}>
                                                    <div className="flex flex-col">
                                                        <Typography
                                                            variant="small"
                                                            color="blue-gray"
                                                            className="font-normal"
                                                        >
                                                            <Link to={`/manager/cards/${cardNo}`}><u>{cardNo}</u></Link>
                                                        </Typography>
                                                        <Typography
                                                            variant="small"
                                                            color="blue-gray"
                                                            className="font-bold opacity-70"
                                                        >
                                                            {guestName}
                                                        </Typography>
                                                        <Typography
                                                            variant="small"
                                                            color="blue-gray"
                                                            className="font-normal opacity-70"
                                                        >
                                                            <a href={`tel:${guestMobile}`} style={{ textDecoration: 'underline' }}>{guestMobile}</a>
                                                        </Typography>
                                                    </div>
                                                </td>
                                                <td className={classes}>
                                                    <div className="flex flex-col">
                                                        <Typography
                                                            variant="small"
                                                            color="blue-gray"
                                                            className="font-normal"
                                                        >
                                                            {new Date(createdOn).toLocaleString()}
                                                        </Typography>
                                                        {bookingType && <Typography
                                                            variant="small"
                                                            color="blue-gray"
                                                            className="font-bold"
                                                        >
                                                            Type: {bookingType}
                                                        </Typography>
                                                        }
                                                        {reference && <Typography
                                                            variant="small"
                                                            color="blue-gray"
                                                            className="font-normal"
                                                        >
                                                            Ref: {reference}
                                                        </Typography>
                                                        }
                                                    </div>
                                                </td>
                                                <td className={classes}>
                                                    <div>
                                                        <Typography
                                                            variant="small"
                                                            color="blue-gray"
                                                            className="font-normal"
                                                        >
                                                            <Chip
                                                                variant="gradient"
                                                                color={status === "ACTIVE" ? "green" : (status === "PENDING" ? "yellow" : "blue-gray")}
                                                                value={status}
                                                                className="py-0.5 px-2 text-[11px] font-medium w-fit"
                                                            />
                                                        </Typography>
                                                    </div>
                                                </td>
                                                <td className={classes}>
                                                    <RoomBookingCard roomDetails={roomDetails} />
                                                </td>
                                                <td className={classes}>
                                                    <div>
                                                        <Typography
                                                            variant="small"
                                                            color="blue-gray"
                                                            className="font-normal"
                                                        >
                                                            {totalAmount}.00
                                                        </Typography>
                                                    </div>
                                                </td>
                                                <td className={classes}>
                                                    <AmountCard payments={paymentDetails} />
                                                </td>
                                                <td className={classes}>
                                                    <div className="flex flex-col">
                                                        <Typography
                                                            variant="small"
                                                            color="blue-gray"
                                                            className="font-normal"
                                                        >
                                                            {documentS3Ids.map((s3Id, i) => (<li className="cursor-pointer"><u key={i} onClick={() => handleProofView(s3Id)}>{"Proof "} {i + 1}</u></li>))}
                                                        </Typography>
                                                    </div>
                                                </td>
                                                <td className={classes}>
                                                    <div>
                                                        <Typography
                                                            variant="small"
                                                            color="blue-gray"
                                                            className="font-normal"
                                                        >
                                                            {discount ?? "0"}.00
                                                        </Typography>
                                                    </div>
                                                </td>
                                                <td className={classes}>
                                                    <div>
                                                        <Typography
                                                            variant="small"
                                                            color="blue-gray"
                                                            className="font-normal"
                                                        >
                                                            {amountDue}.00
                                                        </Typography>
                                                    </div>
                                                </td>
                                                <td className={classes}>
                                                    <div>
                                                        <Typography
                                                            variant="small"
                                                            color="blue-gray"
                                                            className="font-normal"
                                                        >
                                                            {dueReason}
                                                        </Typography>
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    },
                                )}
                        </tbody>
                    </table>
                </CardBody>
            </Card>
            <Dialog size={"xs"} open={isShowProofImage}>
                <DialogHeader className="flex justify-end items-center h-full">
                    <Button
                        variant="text"
                        color="red"
                        onClick={() => setShowProofImage(false)}
                        className="mr-1"
                    >
                        <span>Close</span>
                    </Button>
                </DialogHeader>
                <DialogBody>
                    <img src={imageUrl} />
                </DialogBody>
                <DialogFooter>
                    <Button
                        variant="text"
                        color="red"
                        onClick={() => setShowProofImage(false)}
                        className="mr-1"
                    >
                        <span>Close</span>
                    </Button>
                </DialogFooter>
            </Dialog>
            <Dialog open={isShowIncomePopup} >
                <DialogHeader className="flex items-center h-full">
                    <DialogHeader>Add Income</DialogHeader>
                </DialogHeader>
                <DialogBody>
                    <div className="flex flex-wrap lg:justify-start sm:flex-wrap p-2" >
                        <div className="w-50 mr-3 mt-3 mb-3">
                            <label htmlFor="paymentMode" className="block uppercase text-blueGray-600 text-xs font-bold mb-2">Income Type <span className="text-red-500">*</span></label>
                            <select label="paymentMode" color="blue" className="bg-white text-gray-900 border border-gray-300 rounded-md py-2 pl-3 pr-10 focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                                name="paymentMode" id="paymentMode"
                                onChange={(event) => setType(event.target.value)}
                                value={type}>
                                <option key="" value="">Select</option>
                                <option key="Cash" value="Cash">Cash</option>
                                <option key="UPI" value="UPI">UPI</option>
                                <option key="Card" value="Card">Card</option>
                                <option key="Online Payment" value="Online Payment">Online Booking</option>
                                <option key="Company Payment" value="Company Payment">Company Booking</option>
                                <option key="Future Payment" value="Future Payment">Past Dues</option>
                                <option key="Others" value="Others">Others</option>
                            </select>
                        </div>
                        <div className="w-50 mr-3 mt-3 mb-3">
                            <label htmlFor="amount" className="block uppercase text-blueGray-600 text-xs font-bold mb-2">Amount<span className="text-red-500">*</span></label>
                            <input type="number" id="amount" name="amount"
                                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                value={amount} onChange={(event) => setAmount(parseInt(event.target.value, 10))} />
                        </div>
                        <div className="w-50 mr-3 mt-3 mb-3">
                            <label htmlFor="date" className="block uppercase text-blueGray-600 text-xs font-bold mb-2">Date<span className="text-red-500">*</span></label>
                            <input type="date" className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                value={date} onChange={(event) => setDate(event.target.value)} />
                        </div>
                        <div className="w-50 mr-3 mt-3 mb-3">
                            <label htmlFor="remarks" className="block uppercase text-blueGray-600 text-xs font-bold mb-2">Remarks</label>
                            <input type="text" id="remarks" name="remarks"
                                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                value={remarks} onChange={(event) => setRemarks(event.target.value)} />
                        </div>
                    </div>
                </DialogBody>
                <DialogFooter>
                    <Button
                        variant="text"
                        color="red"
                        onClick={() => setShowIncomePopup(false)}
                        className="mr-1"
                    >
                        <span>Cancel</span>
                    </Button>
                    <Button variant="gradient" color="green" onClick={() => handleMarkCloseInternal()}>
                        <span>Save</span>
                    </Button>
                </DialogFooter>
            </Dialog>
            <Dialog open={isLoading} className="bg-transparent shadow-none" size="md">
                <DialogBody className="bg-transparent shadow-none flex justify-center">
                    <ClockLoader color="#2be60d" />
                </DialogBody>
            </Dialog>
        </div>
    )

}
