import { PencilIcon } from "@heroicons/react/24/solid";
import {
    Card,
    CardBody,
    CardHeader,
    Chip,
    Dialog,
    DialogBody,
    IconButton,
    Tooltip,
    Typography
} from "@material-tailwind/react";
import React, { useEffect, useState } from 'react';
import { ClockLoader } from 'react-spinners';
import { useUserContext } from '../../context';
import { useApi } from "../../hooks/useApi";


const TABLE_HEAD_USER = ["Name", "Hotel(s)", "Status", "Action"];

function Managers() {
    const userStatus = true;

    const [managers, setManagers] = useState([]);
    const { assignments, fetchAccessToken } = useUserContext();
    let hotelDetails = assignments?.hotels.reduce((acc, hotel) => {
        acc.set(hotel.id, hotel.name);
        return acc;
    }, new Map());
    let hotelIds = Array.from(hotelDetails.keys());
    const { getManagers } = useApi();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const getManagersAsync = async (hotelIds) => {
            try {
                const token = await fetchAccessToken();
                let data = await getManagers(hotelIds, token);
                if (data) {
                    setManagers(data);
                }
            } catch (error) {
                console.error('Error fetching managers:', error.message);
            } finally {
                setIsLoading(false);
            }
        };
        if (hotelIds.length > 0) {
            getManagersAsync(hotelIds.join(','));
        }

    }, [hotelIds]);


    return (
        <div>
            <Card className="h-full w-full">
                <CardHeader variant="gradient" color="gray" className="mt-6 mb-8 p-6 flex flex-row justify-between">
                    <Typography variant="h6" color="white">
                        Managers
                    </Typography>
                    <button className="shadow bg-blue-500 hover:bg-blue-700 focus:shadow-outline focus:outline-none text-white font-bold text-xs py-2 px-4 rounded" type="button"
                        onClick={() => alert('TODO')}>
                        Add Manager
                    </button>
                </CardHeader>
                <CardBody className="overflow-scroll px-0 pt-0 pb-2">
                    <table className="mt-0 w-full min-w-max table-auto text-left">
                        <thead>
                            <tr>
                                {TABLE_HEAD_USER.map((head) => (
                                    <th
                                        key={head}
                                        className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
                                    >
                                        <Typography
                                            variant="small"
                                            color="blue-gray"
                                            className="font-normal leading-none opacity-90"
                                        >
                                            {head}
                                        </Typography>
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {managers.map(
                                ({ name, email, hotels }, index) => {
                                    const isLast = index === managers.length - 1;
                                    const classes = isLast
                                        ? "p-4"
                                        : "p-4 border-b border-blue-gray-50";

                                    return (
                                        <tr key={email}>
                                            <td className={classes}>
                                                <div>
                                                    <Typography
                                                        variant="small"
                                                        color="blue-gray"
                                                        className="font-semibold"
                                                    >
                                                        {name}
                                                    </Typography>
                                                    <Typography className="text-xs font-normal text-blue-gray-500">
                                                        {email}
                                                    </Typography>
                                                </div>
                                            </td>
                                            <td className={classes}>
                                                <div className="flex flex-col">
                                                    <Typography
                                                        variant="small"
                                                        color="blue-gray"
                                                        className="font-normal"
                                                    >
                                                        {hotels.map((h) => (<li key={h}>{hotelDetails.get(h)}</li >))}
                                                    </Typography>
                                                </div>
                                            </td>
                                            <td className={classes}>
                                                <Chip
                                                    variant="gradient"
                                                    color={userStatus ? "green" : "blue-gray"}
                                                    value={userStatus ? "active" : "blocked"}
                                                    className="py-0.5 px-2 text-[11px] font-medium w-fit"
                                                />
                                            </td>
                                            <td className={classes}>

                                                <Tooltip content="Edit Details">
                                                    <IconButton variant="text" onClick={() => alert('TODO')}>
                                                        <PencilIcon className="h-4 w-4" />
                                                    </IconButton>
                                                </Tooltip>
                                            </td>
                                        </tr>
                                    );
                                },
                            )}
                        </tbody>
                    </table>
                </CardBody>
            </Card>

            <Dialog open={isLoading} className="bg-transparent shadow-none" size="md">
                <DialogBody className="bg-transparent shadow-none flex justify-center">
                    <ClockLoader color="#2be60d" />
                </DialogBody>
            </Dialog>

        </div>
    )
}

export default React.memo(Managers);
