import { Route, Routes } from "react-router-dom";
import { Admin, Home, LoginCallback, Manager } from "./layouts";
import { useUserContext } from "./context/userContext";


const App = () => {

  const { isAdmin, isAuthenticated } = useUserContext();

  return (
    <Routes>
      <Route path="*" element={<Home />} />
      {isAdmin && <Route path="/admin/*" element={<Admin />} />}
      {isAuthenticated && <Route path="/manager/*" element={<Manager />} />}
      <Route path="/login/callback" element={<LoginCallback />} />
    </Routes>
  )
};

export default App;
