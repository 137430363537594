import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useUserContext } from '../context/userContext';

export default function Home() {
    const navigate = useNavigate();
    const { login, isLoading, isAuthenticated, isAdmin } = useUserContext();

    const [dashboardUrl, setDashboardUrl] = useState("/manager/dashboard");
    useEffect(() => {
        if (isAuthenticated && isAdmin) {
            setDashboardUrl('/admin/dashboard');
        }
    }, [isAuthenticated, isAdmin]);
    return (
        <div className="flex flex-col h-screen">
            <header className="bg-gray-900 text-white py-4 px-6 flex justify-between items-center">
                <div className="flex items-center">
                    <img src="/logo.png" alt="Logo" className="h-8 mr-4" />
                    <h1 className="text-xl font-semibold">InnPro</h1>
                </div>
                {!isLoading && !isAuthenticated && <button className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded" onClick={() => login()}>Login</button>}
                {!isLoading && isAuthenticated && <button className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded" onClick={() => navigate(dashboardUrl)}>Dashboard</button>}

            </header>

            <main className="flex-grow p-6">
                <div className="text-center">
                    <h2 className="text-3xl font-semibold mb-4">Welcome to InnPro</h2>

                </div>
            </main>

            <footer className="bg-gray-900 text-white py-4 px-6 flex justify-center">
                <ul className="flex space-x-4">
                    <li><a href="#" className="hover:text-blue-500">Contact Us</a></li>
                    <li><a href="#" className="hover:text-blue-500">About Us</a></li>
                    <li><a href="#" className="hover:text-blue-500">Services</a></li>
                    <li><a href="#" className="hover:text-blue-500">FAQ</a></li>
                </ul>
            </footer>
        </div>
    )
}
