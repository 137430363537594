import { Button, Dialog, DialogBody, DialogFooter, DialogHeader, IconButton, Tooltip, Typography } from '@material-tailwind/react';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ClockLoader } from 'react-spinners';
import TimePicker from '../../components/time-picker';
import { useUserContext } from '../../context';
import { useApi } from '../../hooks/useApi';
import DatePicker from './../../components/date-picker';
import { MdDelete } from "react-icons/md";
import { FaSync } from "react-icons/fa";

export default function Checkout() {
    const navigate = useNavigate();
    const { state } = useLocation();
    const { getCardNos, checkOutRooms, getCheckInCard, updateCheckOutRooms, uploadDocument, getImageUrl, updateCheckInRooms, deletePayment, addCheckIn } = useApi();
    const { fetchAccessToken, defaultHotel, user, isAdmin, assignments } = useUserContext();
    const [isLoading, setIsLoading] = useState(false);
    const [checkInCard, setCheckInCard] = useState({});
    const [dueReason, setDueReason] = useState("");
    const [checkInDate, setCheckInDate] = useState(getCurrentDate());
    const [checkInTime, setCheckInTime] = useState(getCurrentTime());
    const [checkOutDate, setCheckOutDate] = useState(getCurrentDate(1));
    const [checkOutTime, setCheckOutTime] = useState(getCurrentTime(-1));
    const [newPaymentMode, setNewPaymentMode] = useState("");
    const [newPaymentDate, setNewPaymentDate] = useState(() => formatDate(0));
    const [discount, setDiscount] = useState(0);
    const [newAmount, setNewAmount] = useState(0);
    const [cardNo, setCardNo] = useState(() => (state ? state.cardNo : 0));
    const [guestMobile, setGuestMobile] = useState(() => (state ? state.guestId : ""));
    const [guestName, setGuestName] = useState(() => (state ? state.guestName : ""));
    const [roomCategories, setRoomCategories] = useState([]);
    const [cardStatus, setCardStatus] = useState('ACTIVE');
    const isCheckInTimeLock = true;
    const isCheckoutTimeLock = !isAdmin && checkInCard && checkInCard.card && checkInCard.card.status !== "ACTIVE";
    const fileInputRef = useRef(null);
    const [attachments, setAttachments] = useState([]);
    const [isShowProofImage, setShowProofImage] = useState(false);
    const [imageUrl, setImageUrl] = useState("");
    const [deletedPayments, setDeletedPayments] = useState([]);
    const [totalDiscount, setTotalDiscount] = useState(0);

    function formatDate(daysAdd = 0) {
        const date = new Date();
        date.setDate(date.getDate() + daysAdd);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${year}-${month}-${day}`;
    };

    let getCardNosInternal = async () => {
        if (guestMobile && defaultHotel) {
            setIsLoading(true);
            try {
                const token = await fetchAccessToken();
                const data = await getCardNos(defaultHotel, guestMobile, token);
                if (data && data.cardNos && data.cardNos.length > 0) {
                    if (data.cardNos.length > 1) {
                        alert("Multiple cards exists for the customer.\n" + data.cardNos.join('\n'));
                    }
                    setCardNo(data.cardNos[0]);
                } else {
                    alert("No active card for the customer.");
                }
            }
            catch (ex) {
                alert("Something went wrong.");
            }
            finally {
                setIsLoading(false);
            }
        }
    };

    let getCheckInCardInternal = async () => {
        if (cardNo && defaultHotel) {
            setIsLoading(true);
            try {
                const token = await fetchAccessToken();
                const data = await getCheckInCard(defaultHotel, cardNo, token);
                if (data && data.card) {
                    setCheckInCard(data);
                    setGuestMobile(data.card.guestMobile);
                    setGuestName(data.card.guestName);
                    setDueReason(data.card.dueReason);
                    setCheckInDate(getDateFromDateTime(data.card.roomDetails[0].checkInDateTime));
                    setCheckInTime(getTimeFromDateTime(data.card.roomDetails[0].checkInDateTime));
                    setCardStatus(data.card.status);
                    setDiscount(data.card.discount || 0);
                    if (data.card.status === "ACTIVE") {
                        setCheckOutDate(getCurrentDate());
                        setCheckOutTime(getCurrentTime());
                    } else {
                        setCheckOutDate(getDateFromDateTime(data.card.roomDetails[0].checkOutDateTime));
                        setCheckOutTime(getTimeFromDateTime(data.card.roomDetails[0].checkOutDateTime));
                    }
                }
            }
            catch (ex) {
                alert("Something went wrong.");
            }
            finally {
                setIsLoading(false);
            }
        }
    };

    function parseDate(dateString) {
        const parts = dateString.split('-');
        if (parts.length === 3) {
            const day = parseInt(parts[0], 10);
            const month = parseInt(parts[1], 10) - 1;
            const year = parseInt(parts[2], 10);
            return new Date(year, month, day);
        }
        return null;
    }

    function getDateFromDateTime(dateTimeString) {
        const dateTimeParts = dateTimeString.split(' ');
        const datePart = dateTimeParts[0];
        return parseDate(datePart);
    }

    function getTimeFromDateTime(dateTimeString) {
        const dateTimeParts = dateTimeString.split(' ');
        const timePart = dateTimeParts[1];
        return timePart;
    }

    function getEpochWithDateTime(date, time, seconds) {
        const [hours, minutes] = time.split(':').map(Number);
        const combinedDate = new Date(date);
        combinedDate.setHours(hours);
        combinedDate.setMinutes(minutes);
        combinedDate.setSeconds(seconds);
        combinedDate.setMilliseconds(seconds * 100);

        return combinedDate.getTime();
    }

    function convertToEpoch(dateStr, seconds, daysToAdd = 0, hoursToAdd = 0) {
        const [datePart, timePart] = dateStr.split(' ');

        const [day, month, year] = datePart.split('-').map(Number);
        const [hours, minutes] = timePart.split(':').map(Number);

        const date = new Date(year, month - 1, day + daysToAdd, hours + hoursToAdd, minutes, seconds);

        const epoch = date.getTime();

        return epoch;
    }

    function combineDateAndTime(date, time) {
        const [hours, minutes] = time.split(':').map(Number);
        const combinedDate = new Date(date);
        combinedDate.setHours(hours);
        combinedDate.setMinutes(minutes);

        const formattedDateTime = `${combinedDate.getDate().toString().padStart(2, '0')}-${(combinedDate.getMonth() + 1).toString().padStart(2, '0')}-${combinedDate.getFullYear()} ${combinedDate.getHours().toString().padStart(2, '0')}:${combinedDate.getMinutes().toString().padStart(2, '0')}`;
        return formattedDateTime;
    }

    function getCurrentTime(hoursAdd = 0) {
        const now = new Date();
        const hours = String(now.getHours() + hoursAdd).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        return hours + ':' + minutes;
    }

    function getCurrentDate(daysAdd = 0) {
        let d = new Date();
        d.setDate(d.getDate() + daysAdd);
        d.setHours(0, 0, 0, 0)
        return d;
    }

    useEffect(() => {
        let getCheckInCardInternal = async (cardNo, hotelId) => {
            if (cardNo && hotelId) {
                setIsLoading(true);
                try {
                    const token = await fetchAccessToken();
                    const data = await getCheckInCard(hotelId, cardNo, token);
                    if (data && data.card) {
                        setCheckInCard(data);
                        setGuestMobile(data.card.guestMobile);
                        setGuestName(data.card.guestName);
                        setDueReason(data.card.dueReason);
                        setCheckInDate(getDateFromDateTime(data.card.roomDetails[0].checkInDateTime));
                        setCheckInTime(getTimeFromDateTime(data.card.roomDetails[0].checkInDateTime));
                        setCardStatus(data.card.status);
                        setDiscount(data.card.discount || 0);
                        if (data.card.status === "ACTIVE") {
                            setCheckOutDate(getCurrentDate());
                            setCheckOutTime(getCurrentTime());
                        } else {
                            setCheckOutDate(getDateFromDateTime(data.card.roomDetails[0].checkOutDateTime));
                            setCheckOutTime(getTimeFromDateTime(data.card.roomDetails[0].checkOutDateTime));
                        }
                    }
                }
                catch (ex) {
                    console.error(ex);
                    alert("Something went wrong.");
                }
                finally {
                    setIsLoading(false);
                }
            }
        };
        if (defaultHotel) {
            let hotel = assignments?.hotels.find(hotel => hotel.id.toLowerCase() === defaultHotel.toLowerCase());
            setRoomCategories(hotel.roomTypes);
        }
        if (cardNo && defaultHotel) {

            getCheckInCardInternal(cardNo, defaultHotel);
        }

    }, [defaultHotel]);

    useEffect(() => {

        if (checkInCard && checkInCard.checkIns && checkInCard.card) {
            const { card, checkIns } = checkInCard;
            checkIns.forEach((checkIn, index) => {
                checkIn.checkOutEpoch = getEpochWithDateTime(checkOutDate, checkOutTime, index + 1);
                checkIn.checkOutDateTime = combineDateAndTime(checkOutDate, checkOutTime);
            });
            let totalAmount = 0;
            let totalDiscountTemp = 0;
            card.roomDetails.forEach(roomDetail => {
                const rc = getRoomDetailsByCategory(roomDetail.category);
                let differenceDays = getDateDifference(roomDetail.checkInDateTime, combineDateAndTime(checkOutDate, checkOutTime));
                if (roomDetail.closed === true) {
                    differenceDays = getDateDifference(roomDetail.checkInDateTime, roomDetail.checkOutDateTime);
                }
                roomDetail.totalDays = differenceDays;
                if (roomDetail.closed !== true) {
                    roomDetail.checkOutDateTime = combineDateAndTime(checkOutDate, checkOutTime);
                }
                roomDetail.fare = differenceDays * rc.price;
                totalDiscountTemp += differenceDays * roomDetail.unitDiscount || 0;
                roomDetail.extraBedCost = differenceDays * roomDetail.extraBeds * rc.extraBedCharges;
                roomDetail.gst = ((differenceDays * rc.price) + roomDetail.extraBedCost) * (rc.gst / 100);
                roomDetail.totalAmount = roomDetail.fare + roomDetail.gst + roomDetail.extraBedCost;
                totalAmount += roomDetail.totalAmount;
            });
            card.totalAmount = totalAmount - totalDiscountTemp;
            card.amountDue = card.totalAmount - card.amountReceived - card.discount;
            setTotalDiscount(totalDiscountTemp);
            setCheckInCard({ checkIns, card });
        }

    }, [checkOutDate, checkOutTime]);

    function getRoomDetailsByCategory(category) {
        const rc = roomCategories.find(rc => rc.category === category);
        return rc;
    }

    function getRoomByNumber(roomNo) {
        let hotel = assignments?.hotels.find(hotel => hotel.id.toLowerCase() === defaultHotel.toLowerCase());
        const r = hotel.rooms.find(room => room.roomNo === roomNo);
        return r;
    }

    function getDateDifference(dateString1, dateString2) {
        const [day1, month1, year1, hour1, minute1] = dateString1.split(/[\s:-]/);
        const [day2, month2, year2, hour2, minute2] = dateString2.split(/[\s:-]/);

        const date1 = new Date(year1, month1 - 1, day1, hour1 || 0, minute1 || 0);
        const date2 = new Date(year2, month2 - 1, day2, hour2 || 0, minute2 || 0);

        const differenceMs = Math.abs(date2 - date1);

        const differenceDays = Math.ceil(differenceMs / (1000 * 60 * 60 * 24));

        if (differenceDays === 0) {
            return 1;
        } else {
            return differenceDays;
        }
    }

    let handleAddPayment = () => {
        if (checkInCard && checkInCard.card && checkInCard.checkIns && newPaymentMode && newAmount > 0) {
            const { card, checkIns } = checkInCard;
            const updatedCard = {
                ...card,
                paymentDetails: [...card.paymentDetails, {
                    paymentMode: newPaymentMode,
                    paymentAmount: newAmount,
                    paymentDateTime: getEpoch(newPaymentDate),
                    isNew: true
                }]
            };
            const paymentReceived = updatedCard.paymentDetails.reduce((accumulator, current) => {
                return accumulator + current.paymentAmount;
            }, 0);
            updatedCard.amountReceived = paymentReceived;
            updatedCard.amountDue = updatedCard.totalAmount - updatedCard.amountReceived;
            setCheckInCard({ checkIns, card: updatedCard });
            setNewAmount(0);
            setNewPaymentMode("");
        }
    };

    function getEpoch(date) {
        const combinedDate = new Date(date);
        let now = new Date();
        let hours = now.getHours();
        let minutes = now.getMinutes();
        let seconds = now.getSeconds();
        combinedDate.setHours(hours);
        combinedDate.setMinutes(minutes);
        combinedDate.setSeconds(seconds);
        combinedDate.setMilliseconds(seconds * 100);

        return combinedDate.getTime();
    }

    useEffect(() => {
        if (checkInCard && checkInCard.card) {
            const { card, checkIns } = checkInCard;
            const updatedCard = {
                ...card
            };
            updatedCard.discount = discount;
            updatedCard.amountDue = updatedCard.totalAmount - updatedCard.amountReceived - updatedCard.discount;
            setCheckInCard({ checkIns, card: updatedCard })
            setNewAmount(0);
            setNewPaymentMode("");
        }
    }, [discount]);

    let handleCheckOutInternal = async () => {
        if (checkInCard && checkInCard.card && checkInCard.checkIns) {
            if (checkInCard.card.status === "CLOSE") {
                alert("Card is already closed.");
                return;
            }
            if (checkInCard.card.amountDue > 0 && dueReason === "") {
                alert("There is due amount exists in the card. please select due reason.");
                return;
            }
            if (checkInCard.card.amountDue > 0) {
                checkInCard.card.dueReason = dueReason;
            } else {
                checkInCard.card.dueReason = "";
            }
            if (attachments.length > 1) {
                alert("Please upload payment proofs");
                return;
            }
            alert("Please add payment proofs before you commit.")
            const confirm = window.confirm("Do you want to proceed Checkout?");
            if (confirm) {
                setIsLoading(true);
                try {
                    const token = await fetchAccessToken();
                    let checkOutCard = { card: checkInCard.card, checkOuts: checkInCard.checkIns };
                    checkOutCard.card.status = checkInCard.card.amountDue > 0 ? "PENDING" : "CLOSE";
                    if (cardStatus === "ACTIVE") {
                        await checkOutRooms(defaultHotel, cardNo, checkOutCard, token);
                        alert("Card checked out successfully.");
                    } else {
                        await updateCheckOutRooms(defaultHotel, cardNo, checkOutCard, token);
                        alert("Card updated successfully.");
                    }
                    navigate("/manager/dashboard");
                }
                catch (ex) {
                    alert("Something went wrong.");
                }
                finally {
                    setIsLoading(false);
                }
            }
        }
    };

    let handleUpdateCardPayments = async () => {
        const confirm = window.confirm("Do you want to update card payments?");
        if (confirm) {
            setIsLoading(true);
            try {
                const token = await fetchAccessToken();
                if (deletedPayments && deletedPayments.length) {
                    await Promise.all(deletedPayments.map(async (payment) => {
                        return await deletePayment(defaultHotel, payment.paymentDateTime, token);
                    }));
                }
                checkInCard.card.dueReason = dueReason;
                if (cardStatus === "ACTIVE") {
                    let updateCheckInCard = { card: checkInCard.card, checkIns: checkInCard.checkIns };
                    await updateCheckInRooms(defaultHotel, cardNo, updateCheckInCard, token);
                    alert("Payments updated successfully.");
                } else {
                    let checkOutCard = { card: checkInCard.card, checkOuts: checkInCard.checkIns };
                    await updateCheckOutRooms(defaultHotel, cardNo, checkOutCard, token);
                    alert("Payments updated successfully.");
                }
                navigate("/manager/dashboard");
            }
            catch (ex) {
                alert("Something went wrong.");
            }
            finally {
                setIsLoading(false);
            }
        }
    };

    let handleSyncIssueInternal = async () => {
        let checkIns = [];
        const { card } = checkInCard;
        card.roomDetails.forEach((roomDetail, index) => {
            if (roomDetail.closed !== true) {
                checkIns.push({
                    hotelId: card.hotelId,
                    roomNo: roomDetail.roomNo,
                    checkInEpoch: convertToEpoch(roomDetail.checkInDateTime, index + 1),
                    checkInDateTime: roomDetail.checkInDateTime,
                    checkOutEpoch: convertToEpoch(roomDetail.checkInDateTime, index + 1, 1, -1),
                    checkOutDateTime: epochToString(convertToEpoch(roomDetail.checkInDateTime, index + 1, 1, -1)),
                    cardNo: card.cardNo,
                    guestName: card.guestName,
                    guestMobile: card.guestMobile,
                    manager: user.name,
                    bookingType: card.bookingType,
                    category: roomDetail.category
                });
            }
        });
        if (checkIns.length > 0) {
            setIsLoading(true);
            try {
                const token = await fetchAccessToken();
                const responses = await Promise.all(checkIns.map(async (checkIn) => {
                    return addCheckIn(card.hotelId, card.cardNo, checkIn, token);
                }));
                navigate("/manager/dashboard")
            }
            catch (ex) {
                alert("Something went wrong.");
            }
            finally {
                setIsLoading(false);
            }
        }
    };

    function epochToString(epoch) {
        const date = new Date(epoch);

        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        const hour = date.getHours().toString().padStart(2, '0');
        const minute = date.getMinutes().toString().padStart(2, '0');

        const dateString = `${day}-${month}-${year} ${hour}:${minute}`;

        return dateString;
    }

    const handleDeleteAttachment = (index) => {
        const newItems = attachments.filter((_, i) => i !== index);
        setAttachments(newItems);
    };

    const handleFileChange = (e) => {
        setIsLoading(true);
        let file = e.target.files[0];
        if (file && (file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png')) {
            compressImage(file, (compressedBlob) => {
                setAttachments([...attachments, compressedBlob]);
                if (fileInputRef.current) {
                    fileInputRef.current.value = '';
                }
                setIsLoading(false);
            });
        } else {
            setIsLoading(false);
            alert('Please upload a JPEG or PNG image.');
        }
    };

    const compressImage = (file, callback) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const img = new Image();
            img.src = e.target.result;
            img.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                canvas.width = img.width;
                canvas.height = img.height;
                ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

                const fileType = file.type;
                const isJPEG = fileType === 'image/jpeg' || fileType === 'image/jpg';

                let quality = isJPEG ? 1.0 : undefined;
                const maxFileSize = 1000000; // 1MB

                const compress = () => {
                    canvas.toBlob((blob) => {
                        if (blob.size <= maxFileSize || (isJPEG && quality < 0.1)) {
                            callback(blob);
                        } else if (isJPEG) {
                            quality -= 0.1;
                            compress();
                        } else {
                            // For PNG, if the first compression is too large, we can't reduce quality, so we just use the first result
                            callback(blob);
                        }
                    }, fileType, quality);
                };

                compress();
            };
        };
        reader.readAsDataURL(file);
    };

    let handleProofView = async (documentS3Id) => {
        try {
            setIsLoading(true);
            const token = await fetchAccessToken();
            const imageUrl = await getImageUrl(documentS3Id, token);
            if (imageUrl) {
                setImageUrl(imageUrl);
                setShowProofImage(true);
            }
        } catch (ex) {
            alert("Error occurred while viewing the Proof.");
        } finally {
            setIsLoading(false);
        }
    };

    function convertBlobToBase64(blob) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                resolve(reader.result);
            };
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });
    }

    let handleUploadAttachments = async () => {
        try {
            if (attachments.length < 1) {
                alert("Please capture/upload attachments");
                return;
            }
            setIsLoading(true);
            const token = await fetchAccessToken();
            const responses = await Promise.all(attachments.map(async (attachment) => {
                let base64String = await convertBlobToBase64(attachment);
                return await uploadDocument(base64String, token);
            }));
            const { card, checkIns } = checkInCard;
            const updatedCard = {
                ...card,
                documentS3Ids: [...card.documentS3Ids, ...responses]
            };
            setCheckInCard({ checkIns, card: updatedCard });
            setAttachments([]);
        } catch (ex) {
            alert("Error occurred while uploading proofs.");
        } finally {
            setIsLoading(false);
        }
    };

    const handleDeletePayment = async (paymentDetail) => {
        if (checkInCard && checkInCard.card && checkInCard.checkIns && paymentDetail) {
            const { card, checkIns } = checkInCard;
            const updatedCard = {
                ...card,
                paymentDetails: card.paymentDetails.filter(pd => pd.paymentDateTime != paymentDetail.paymentDateTime)
            };
            const paymentReceived = updatedCard.paymentDetails.reduce((accumulator, current) => {
                return accumulator + current.paymentAmount;
            }, 0);
            updatedCard.amountReceived = paymentReceived;
            updatedCard.amountDue = updatedCard.totalAmount - updatedCard.amountReceived;
            setCheckInCard({ checkIns, card: updatedCard });
            setDeletedPayments([...deletedPayments, paymentDetail]);
        }
    };

    return (
        <div>
            <section className="py-1 bg-blueGray-50">
                <div className="w-full lg:w-10/12 px-4 mx-auto mt-6">
                    <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
                        <div className="rounded-t bg-white mb-0 px-6 py-6">
                            <div className="text-center flex justify-between">
                                <h6 className="text-blueGray-700 text-xl font-bold">
                                    Check Out
                                </h6>
                            </div>
                        </div>
                        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                            <div className="flex flex-wrap mt-3">
                                <div className="flex flex-row w-full mb-3 px-4">
                                    <div className="relative w-full mb-3">
                                        <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" >
                                            Card No<span className="text-red-500">*</span>
                                        </label>
                                        <input type="number" id="cardNo" name="cardNo"
                                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-150 ease-linear transition-all duration-150"
                                            value={cardNo} onChange={(e) => setCardNo(parseInt(e.target.value, 10))} />
                                        <Button className="rounded-full mt-2 ml-3" size="sm" onClick={getCheckInCardInternal}>Search</Button>
                                    </div>
                                </div>
                                <div className="flex flex-row w-full mb-3 px-4">
                                    <div className="relative w-full mb-3">
                                        <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" >
                                            Guest Mobile<span className="text-red-500">*</span>
                                        </label>
                                        <input type="tel" id="guestMobile" name="guestMobile" className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            value={guestMobile} onChange={(e) => setGuestMobile(e.target.value)} placeholder="Enter guest mobile number" />
                                        <Button className="rounded-full mt-2" size="sm" onClick={getCardNosInternal}>Search</Button>
                                    </div>
                                    <div className="relative w-full mb-3 ml-3">
                                        <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" >
                                            Guest Name<span className="text-red-500">*</span>
                                        </label>
                                        <input type="text" id="guestName" name="guestName" className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            readOnly={true} value={guestName} placeholder="Enter full name" />
                                    </div>
                                </div>
                                <div className="w-full lg:w-6/12 px-4">
                                    <div className="relative w-full mb-3">
                                        <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" >
                                            Check-In Date<span className="text-red-500">*</span>
                                        </label>
                                        <div className="flex flex-row">
                                            <DatePicker defaultDate={checkInDate} onDateSelect={setCheckInDate} isDisabled={isCheckInTimeLock} />
                                            <TimePicker defaultTime={checkInTime} onTimeChange={setCheckInTime} isDisabled={isCheckInTimeLock} />
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full lg:w-6/12 px-4">
                                    <div className="relative w-full mb-3">
                                        <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" >
                                            Checkout Date<span className="text-red-500">*</span>
                                        </label>
                                        <div className="flex flex-row">
                                            <DatePicker defaultDate={checkOutDate} onDateSelect={setCheckOutDate} isDisabled={isCheckoutTimeLock} />
                                            <TimePicker defaultTime={checkOutTime} onTimeChange={setCheckOutTime} isDisabled={isCheckoutTimeLock} />
                                        </div>
                                    </div>
                                </div>
                                {checkInCard && checkInCard.card && <div className="flex flex-row w-full mb-3 px-4">
                                    <div className="flex flex-row w-full lg:w-6/12 px-4">
                                        <div className="relative w-full mb-3">
                                            <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" >
                                                Card Status
                                            </label>
                                            <Typography
                                                variant="small"
                                                className="flex items-center font-normal text-blue-gray-600"
                                            >
                                                {cardStatus}
                                            </Typography>
                                        </div>
                                        <div className="relative w-full mb-3">
                                            <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" >
                                                Booking Type
                                            </label>
                                            <Typography
                                                variant="small"
                                                className="flex items-center font-normal text-blue-gray-600"
                                            >
                                                {checkInCard.card.bookingType}
                                            </Typography>
                                        </div>
                                    </div>
                                </div>}
                                {checkInCard && checkInCard.card && checkInCard.checkIns &&
                                    <div className="w-full lg:w-12/12 px-4 overflow-scroll">
                                        <div className="relative w-full mb-3">
                                            <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" >
                                                Room Details
                                                {isAdmin && checkInCard && checkInCard.card && checkInCard.checkIns && checkInCard.checkIns.length === 0 && cardStatus === "ACTIVE" &&
                                                    <Tooltip content="Sync rooms to Dashboard">
                                                        <IconButton variant="text" onClick={handleSyncIssueInternal} className="ml-5">
                                                            <FaSync color="red" className="h-5 w-5  text-red-500" />
                                                        </IconButton>
                                                    </Tooltip>}
                                            </label>
                                            <table className="min-w-full divide-y divide-gray-200 bg-white shadow overflow-hidden rounded-lg">
                                                <thead className="bg-gray-50">
                                                    <tr>
                                                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Room No</th>
                                                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Room Type</th>
                                                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Days</th>
                                                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Room Fare</th>
                                                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Extra Bed Cost</th>
                                                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">GST (12%)</th>
                                                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Total Cost</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="bg-white divide-y divide-gray-200">
                                                    {checkInCard.card.roomDetails.map((roomDetail, i) => <tr>
                                                        <td className="px-6 py-4 whitespace-nowrap text-gray-700">{roomDetail.roomNo}</td>
                                                        <td className="px-6 py-4 whitespace-nowrap text-gray-700">{roomDetail.category}</td>
                                                        <td className="px-6 py-4 whitespace-nowrap text-gray-700">
                                                            <Tooltip
                                                                animate={{
                                                                    mount: { scale: 1, y: 0 },
                                                                    unmount: { scale: 0, y: 25 },
                                                                }}
                                                                content={<div>
                                                                    <div>
                                                                        Check-In: {roomDetail.checkInDateTime}
                                                                    </div>
                                                                    <div>
                                                                        Check Out:  {roomDetail.checkOutDateTime}
                                                                    </div>
                                                                </div>} >
                                                                <Typography
                                                                    variant="small"
                                                                    color="blue-gray"
                                                                    className="font-normal cursor-pointer underline"
                                                                >
                                                                    {roomDetail.totalDays}
                                                                </Typography>
                                                            </Tooltip>

                                                        </td>
                                                        <td className="px-6 py-4 whitespace-nowrap text-gray-700">{roomDetail.fare}.00</td>
                                                        <td className="px-6 py-4 whitespace-nowrap text-gray-700">{roomDetail.extraBedCost}.00</td>
                                                        <td className="px-6 py-4 whitespace-nowrap text-gray-700">{roomDetail.gst}.00</td>
                                                        <td className="px-6 py-4 whitespace-nowrap text-gray-700">{roomDetail.totalAmount}.00</td>
                                                    </tr>)}
                                                    <tr className="bg-gray-50">
                                                        <td className="px-6 py-4 whitespace-nowrap text-right" colSpan={6} >Total Amount</td>
                                                        <td className="px-6 py-4 whitespace-nowrap">{checkInCard.card.totalAmount + totalDiscount}.00</td>
                                                    </tr>
                                                    <tr className="bg-gray-50">
                                                        <td className="px-6 py-4 whitespace-nowrap text-right" colSpan={6} >Discount</td>
                                                        <td className="px-6 py-4 whitespace-nowrap">{totalDiscount}.00</td>
                                                    </tr>
                                                    <tr className="bg-gray-50">
                                                        <td className="px-6 py-4 whitespace-nowrap text-right" colSpan={6} >Net Amount</td>
                                                        <td className="px-6 py-4 whitespace-nowrap">{checkInCard.card.totalAmount}.00</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>}
                                {checkInCard && checkInCard.card && checkInCard.checkIns &&
                                    <div className="w-full lg:w-12/12 px-4 overflow-scroll">
                                        <div className="relative w-full mb-3">
                                            <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" >
                                                Payment Details
                                            </label>
                                            <table className="min-w-full divide-y divide-gray-200 bg-white shadow overflow-hidden rounded-lg">
                                                <thead className="bg-gray-50">
                                                    <tr>
                                                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
                                                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Mode</th>
                                                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Amount</th>
                                                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"></th>
                                                    </tr>
                                                </thead>
                                                <tbody className="bg-white divide-y divide-gray-200">
                                                    {checkInCard.card.paymentDetails.map((paymentDetail, i) =>
                                                        <tr className={`${paymentDetail.isNew ? "bg-blue-200" : ""}`}>
                                                            <td className="px-6 py-4 whitespace-nowrap text-gray-700">{epochToString(paymentDetail.paymentDateTime)}</td>
                                                            <td className="px-6 py-4 whitespace-nowrap text-gray-700">{paymentDetail.paymentMode}</td>
                                                            <td className="px-6 py-4 whitespace-nowrap text-gray-700">{paymentDetail.paymentAmount}.00</td>
                                                            <td className="px-6 py-4 whitespace-nowrap text-gray-700">
                                                                {isAdmin && <Tooltip content="Delete">
                                                                    <IconButton variant="text" onClick={() => handleDeletePayment(paymentDetail)}>
                                                                        <MdDelete className="h-6 w-6" />
                                                                    </IconButton>
                                                                </Tooltip>
                                                                }
                                                            </td>
                                                        </tr>
                                                    )}
                                                    <tr className="bg-gray-50">
                                                        <td className="px-6 py-4 whitespace-nowrap text-right" colSpan={2} >Amount Received</td>
                                                        <td className="px-6 py-4 whitespace-nowrap">{checkInCard.card.amountReceived}.00</td>
                                                        <td></td>
                                                    </tr>
                                                    <tr className="bg-gray-50">
                                                        <td className="px-6 py-4 whitespace-nowrap text-right" colSpan={2} >Discount</td>
                                                        <td className="px-6 py-4 whitespace-nowrap">
                                                            <input type="number" id="discount" name="discount"
                                                                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-150 ease-linear transition-all duration-150"
                                                                value={discount} onChange={(e) => setDiscount(e.target.value)} />
                                                        </td>
                                                        <td></td>
                                                    </tr>
                                                    <tr className="bg-gray-50">
                                                        <td className="px-6 py-4 whitespace-nowrap text-right" colSpan={2} >Balance Due</td>
                                                        <td className="px-6 py-4 whitespace-nowrap">{checkInCard.card.amountDue}.00</td>
                                                        <td></td>
                                                    </tr>
                                                    <tr className="bg-gray-50">
                                                        <td className="px-6 py-4 whitespace-nowrap text-right" colSpan={2} >Due Reason</td>
                                                        <td className="px-6 py-4 whitespace-nowrap">
                                                            <select label="Due Reason" color="blue" className="bg-white text-gray-900 border border-gray-300 rounded-md py-2 pl-3 pr-10 focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                                                                name="dueReason" id="dueReason"
                                                                onChange={(e) => setDueReason(e.target.value)}
                                                                value={dueReason}>
                                                                <option key="" value="">Select</option>
                                                                <option key="Online Booking" value="Online Booking">Online Booking</option>
                                                                <option key="Company Booking" value="Company Booking">Company Booking</option>
                                                                <option key="Future Payment" value="Future Payment">Future Payment</option>
                                                            </select>
                                                        </td>
                                                        <td></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <div className="flex flex-wrap lg:justify-start sm:flex-wrap p-2" >
                                                <div className="w-50 mr-3 mt-3 mb-3">
                                                    <label htmlFor="newPaymentDate" className="block uppercase text-blueGray-600 text-xs font-bold mb-2">Date<span className="text-red-500">*</span></label>
                                                    <input type="date" className="border-0 ml-2 mr-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                        value={newPaymentDate} onChange={(event) => setNewPaymentDate(event.target.value)} />
                                                </div>
                                                <div className="w-50 mr-3 mt-3 mb-3">
                                                    <label htmlFor="newPaymentMode" className="block uppercase text-blueGray-600 text-xs font-bold mb-2">Payment Type <span className="text-red-500">*</span></label>
                                                    <select label="Payment Type" color="blue" className="bg-white text-gray-900 border border-gray-300 rounded-md py-2 pl-3 pr-10 focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                                                        name="newPaymentMode" id="newPaymentMode"
                                                        onChange={(e) => setNewPaymentMode(e.target.value)}
                                                        value={newPaymentMode}>
                                                        <option key="" value="">Select</option>
                                                        <option key="Cash" value="Cash">Cash</option>
                                                        <option key="UPI" value="UPI">UPI</option>
                                                        <option key="Card" value="Card">Card</option>
                                                    </select>
                                                </div>
                                                <div className="w-50 mr-3 mt-3 mb-3">
                                                    <label htmlFor="newAmount" className="block uppercase text-blueGray-600 text-xs font-bold mb-2">Amount<span className="text-red-500">*</span></label>
                                                    <input type="number" id="newAmount" name="newAmount" min="0"
                                                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                        value={newAmount} onChange={(e) => setNewAmount(parseInt(e.target.value, 10))} />
                                                </div>
                                                <div className="w-50 mr-3 mt-9 mb-3">
                                                    <label htmlFor="newAmount" className="block uppercase text-blueGray-600 text-xs font-bold mb-2"><span className="text-red-500">{" "}</span></label>
                                                    <button className="shadow bg-purple-500 hover:bg-purple-400 focus:shadow-outline focus:outline-none text-white font-bold text-xs py-2 px-4 rounded" type="button"
                                                        style={{ height: '2rem' }} onClick={handleAddPayment}>
                                                        Collect
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex flex-wrap">
                                            <div className="relative w-full mb-3">
                                                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" >
                                                    Payment proofs
                                                </label>
                                                <div className="flex flex-col">
                                                    <div className="flex flex-col lg:w-4/12">
                                                        <div className="py-3">
                                                            <input type="file" accept="image/*" onChange={handleFileChange} ref={fileInputRef} />
                                                        </div>
                                                    </div>
                                                    <div className="relative w-full px-5">
                                                        {
                                                            attachments.map((image, i) =>
                                                                <div className="flex flex-row">
                                                                    <img key={i} src={URL.createObjectURL(image)} style={{ height: "100px", width: "100px", margin: "5px" }} />
                                                                    <IconButton variant="text" onClick={() => handleDeleteAttachment(i)}>
                                                                        <MdDelete className="h-6 w-6" />
                                                                    </IconButton>
                                                                </div>
                                                            )
                                                        }
                                                        <ul>
                                                            {checkInCard.card.documentS3Ids.map((s3Id, i) => <li className="cursor-pointer"><u key={i} onClick={() => handleProofView(s3Id)}>{"Payment Proof "} {i + 1}</u></li>)}
                                                        </ul>
                                                        <button className="shadow bg-blue-500 hover:bg-blue-700 focus:shadow-outline focus:outline-none text-white font-bold text-xs py-2 px-4  mr-2 rounded" type="button"
                                                            onClick={handleUploadAttachments}>
                                                            Upload
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>}
                            </div>
                        </div>
                        <div className="w-full flex justify-end  mb-0 px-6 py-6">
                            {cardStatus === "CLOSE" &&
                                <button className="bg-purple-500 text-white hover:bg-purple-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-2 ease-linear transition-all duration-150" type="button"
                                    onClick={() => alert("TODO")}>
                                    Mark Pending
                                </button>
                            }
                            {isAdmin &&
                                <button className="bg-green-500 text-white hover:bg-green-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-2 ease-linear transition-all duration-150" type="button"
                                    onClick={handleUpdateCardPayments}>
                                    Update Payments
                                </button>
                            }
                            <button className="bg-blue-500 text-white hover:bg-blue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-2 ease-linear transition-all duration-150" type="button"
                                onClick={handleCheckOutInternal}>
                                Check Out
                            </button>
                        </div>
                    </div>

                </div>
            </section>
            <Dialog open={isLoading} className="bg-transparent shadow-none" size="md">
                <DialogBody className="bg-transparent shadow-none flex justify-center">
                    <ClockLoader color="#2be60d" />
                </DialogBody>
            </Dialog>
            <Dialog size={"xs"} open={isShowProofImage}>
                <DialogHeader className="flex justify-end items-center h-full">
                    <Button
                        variant="text"
                        color="red"
                        onClick={() => setShowProofImage(false)}
                        className="mr-1"
                    >
                        <span>Close</span>
                    </Button>
                </DialogHeader>
                <DialogBody>
                    <img src={imageUrl} />
                </DialogBody>
                <DialogFooter>
                    <Button
                        variant="text"
                        color="red"
                        onClick={() => setShowProofImage(false)}
                        className="mr-1"
                    >
                        <span>Close</span>
                    </Button>
                </DialogFooter>
            </Dialog>
        </div>
    )
}
