import React, { useEffect, useState } from 'react';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Dialog, DialogBody,
    DialogFooter,
    DialogHeader,
    Checkbox,
    Typography
} from "@material-tailwind/react";
import { ClockLoader } from 'react-spinners';
import { useUserContext } from '../../context';
import { useApi } from '../../hooks/useApi';

const TABLE_HEAD = ["S.No", "Date", "Shifts"];

export default function Attendance() {

    const [isLoading, setIsLoading] = useState(false);
    const [employees, setEmployees] = useState([]);
    const [selectedEmployee, setSelectedEmployee] = useState("");
    const [newEmployee, setNewEmployee] = useState("");
    const [attendanceLogs, setAttendanceLogs] = useState([]);
    const [attendanceDate, setAttendanceDate] = useState(() => formatDate(0));
    const [dayShift, setDayShift] = useState(false);
    const [nightShift, setNightShift] = useState(false);
    const { fetchAccessToken, defaultHotel } = useUserContext();
    const { getEmployees, saveEmployee, logAttendance, getAttendances } = useApi();

    const [showAddEmployeePopup, setShowAddEmployeePopup] = useState(false);
    const [showLogAttendancePopup, setShowLogAttendancePopup] = useState(false);

    const [startDate, setStartDate] = useState(() => getCurrentMonthFirstDay());
    const [endDate, setEndDate] = useState(() => formatDate(1));

    function getCurrentMonthFirstDay() {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        return `${year}-${month}-01`;
    }

    function getEpoch(date, time, seconds = 0) {
        const [hours, minutes] = time.split(':').map(Number);
        const combinedDate = new Date(date);
        combinedDate.setHours(hours);
        combinedDate.setMinutes(minutes);
        combinedDate.setSeconds(seconds);
        combinedDate.setMilliseconds(seconds * 100);

        return combinedDate.getTime();
    }

    function formatDate(daysAdd = 0) {
        const date = new Date();
        date.setDate(date.getDate() + daysAdd);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${year}-${month}-${day}`;
    };


    useEffect(() => {
        let getExpensesInternal = async (hotelId) => {
            setIsLoading(true);
            try {
                const token = await fetchAccessToken();
                const data = await getEmployees(hotelId, token);
                if (data && data.length) {
                    setEmployees(data);
                } else {
                    setEmployees([]);
                }
            } catch (ex) {
                setEmployees([]);
            } finally {
                setIsLoading(false);
            }
        }
        if (defaultHotel) {
            getExpensesInternal(defaultHotel);
        }

    }, [defaultHotel]);

    const saveEmployeeInternal = async () => {
        if (!newEmployee) {
            return;
        }
        setShowAddEmployeePopup(false)
        setIsLoading(true);
        try {
            const token = await fetchAccessToken();
            await saveEmployee(defaultHotel, { name: newEmployee }, token);
            await getEmployeesInternal();
            setNewEmployee("");
        } catch (ex) {
            setEmployees([]);
        } finally {
            setIsLoading(false);
        }
    };

    const getEmployeesInternal = async () => {
        setIsLoading(true);
        try {
            const token = await fetchAccessToken();
            const data = await getEmployees(defaultHotel, token);
            if (data && data.length) {
                setEmployees(data);
            } else {
                setEmployees([]);
            }
        } catch (ex) {
            setEmployees([]);
        } finally {
            setIsLoading(false);
        }
    };

    const getAttendancesInternal = async () => {
        setIsLoading(true);
        try {
            const token = await fetchAccessToken();
            let empName = selectedEmployee.replace(/\s+/g, '');
            const start = getEpoch(startDate, "00:00");
            const end = getEpoch(endDate, "23:59");
            const data = await getAttendances(defaultHotel, empName, start, end, token);
            if (data && data.length) {
                setAttendanceLogs(data);
            } else {
                setAttendanceLogs([]);
            }
        } catch (ex) {
            setAttendanceLogs([]);
        } finally {
            setIsLoading(false);
        }
    };

    const logAttendanceInternal = async () => {
        if (!selectedEmployee || (!dayShift && !nightShift)) {
            return;
        }
        setShowLogAttendancePopup(false);
        setIsLoading(true);
        try {
            const token = await fetchAccessToken();
            const payload = {
                employee: selectedEmployee,
                date: formatDateToDDMMYYYY(attendanceDate),
                dateEpoch: getEpoch(attendanceDate, "08:00"),
                shifts: []
            }
            if (dayShift) {
                payload.shifts.push("DAY");
            }
            if (nightShift) {
                payload.shifts.push("NIGHT");
            }
            await logAttendance(defaultHotel, payload, token);
            await getAttendancesInternal();
            setDayShift(false);
            setNightShift(false);
            setAttendanceDate(formatDate(0));
        } catch (ex) {
            setEmployees([]);
        } finally {
            setIsLoading(false);
        }
    };

    function formatDateToDDMMYYYY(date) {
        const combinedDate = new Date(date)
        let day = combinedDate.getDate();
        let month = combinedDate.getMonth() + 1;
        let year = combinedDate.getFullYear();
        day = day < 10 ? '0' + day : day;
        month = month < 10 ? '0' + month : month;
        return `${day}-${month}-${year}`;
    }

    const getTotal = (data) => {
        return data.reduce((total, item) => {
            return total + (item.shifts ? item.shifts.length : 0);
        }, 0);
    };


    return (
        <div>
            <Card className="h-full w-full">
                <CardHeader variant="gradient" color="gray" className="mt-6 mb-8 p-6 flex flex-row justify-between">
                    <Typography variant="h6" color="white">
                        Attendance
                    </Typography>
                    <div>
                        <button className="shadow bg-purple-500 hover:bg-purple-700 focus:shadow-outline focus:outline-none text-white font-bold text-xs py-2 px-4 mr-4 rounded" type="button"
                            onClick={() => setShowLogAttendancePopup(true)} >
                            Log Attendance
                        </button>
                        <button className="shadow bg-blue-500 hover:bg-blue-700 focus:shadow-outline focus:outline-none text-white font-bold text-xs py-2 px-4 rounded" type="button"
                            onClick={() => setShowAddEmployeePopup(true)} >
                            Add Employee
                        </button>
                    </div>
                </CardHeader>
                <CardBody className="overflow-scroll px-0 pt-0 pb-2">
                    <div className="flex flex-wrap">
                        <div className="w-full lg:w-3/12 px-4">
                            <div className="h-10 flex justify-between sm:justify-start">
                                <Typography className="mt-2">
                                    Employee:
                                </Typography>
                                <div className="flex flex-row">
                                    <select label="filterType" color="blue" className="ml-4 mb-1 bg-white text-gray-900 border border-gray-300 rounded-md py-2 pl-3 pr-10 focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                                        name="filterType" id="filterType"
                                        onChange={(event) => setSelectedEmployee(event.target.value)}
                                        value={selectedEmployee}>
                                        <option key="" value="">Select</option>
                                        {employees.map((e, i) => <option key={i} value={e.name}>{e.name}</option>)}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="w-full lg:w-3/12 px-4">
                            <div className="h-10 flex justify-between sm:justify-start">
                                <Typography className="mt-2">
                                    Start:
                                </Typography>
                                <div className="flex flex-row">
                                    <input type="date" className="border-0 ml-2 mr-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        value={startDate} onChange={(event) => setStartDate(event.target.value)} />
                                </div>
                            </div>
                        </div>
                        <div className="w-full lg:w-3/12 px-4">
                            <div className="h-10 flex justify-between sm:justify-start">
                                <Typography className="mt-2">
                                    End:
                                </Typography>
                                <div className="flex flex-row">
                                    <input type="date" className="border-0  ml-2 mr-2  px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        value={endDate} onChange={(event) => setEndDate(event.target.value)} />
                                </div>
                            </div>
                        </div>
                        <div className="w-full sm:w-3/12 p-1">
                            <div className="h-10 flex justify-end">
                                <button className="bg-pink-500 text-white hover:bg-pink-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-2 ease-linear transition-all duration-150"
                                    type="button" onClick={getAttendancesInternal}>
                                    Get
                                </button>
                            </div>
                        </div>
                    </div>
                    <table className="mt-0 w-full min-w-max table-auto text-left">
                        <thead>
                            <tr>
                                {TABLE_HEAD.map((head) => (
                                    <th
                                        key={head}
                                        className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
                                    >
                                        <Typography
                                            variant="small"
                                            color="blue-gray"
                                            className="font-normal leading-none opacity-70"
                                        >
                                            {head}
                                        </Typography>
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {attendanceLogs.map(
                                ({ date, shifts }, index) => {
                                    const isLast = index === attendanceLogs.length - 1;
                                    const classes = isLast
                                        ? "p-2"
                                        : "p-2 border-b border-blue-gray-50";
                                    return (
                                        <tr key={index}>
                                            <td className={classes}>
                                                <Typography
                                                    variant="small"
                                                    color="blue-gray"
                                                    className="font-normal"
                                                >
                                                    {index + 1}
                                                </Typography>
                                            </td>
                                            <td className={classes}>
                                                <div className="flex flex-col">
                                                    <Typography
                                                        variant="small"
                                                        color="blue-gray"
                                                        className="font-normal"
                                                    >
                                                        {date}
                                                    </Typography>
                                                </div>
                                            </td>
                                            <td className={classes}>
                                                <div>
                                                    <Typography
                                                        variant="small"
                                                        color="blue-gray"
                                                        className="font-normal"
                                                    >
                                                        {shifts.map((s) => (<li key={s}>{s}</li >))}
                                                    </Typography>
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                },
                            )}
                        </tbody>
                    </table>
                    <div className="flex flex-wrap">
                        <div className="w-full lg:w-12/12 px-4">
                            <div className="h-10 flex justify-between sm:justify-center">
                                <Typography className="mt-2">
                                    Total Shifts:
                                </Typography>
                                <Typography className="ml-2 mt-2 font-bold">
                                    {getTotal(attendanceLogs)}
                                </Typography>

                            </div>
                        </div>
                    </div>
                </CardBody>
            </Card>
            <Dialog open={isLoading} className="bg-transparent shadow-none" size="md">
                <DialogBody className="bg-transparent shadow-none flex justify-center">
                    <ClockLoader color="#2be60d" />
                </DialogBody>
            </Dialog>
            <Dialog open={showAddEmployeePopup}>
                <DialogHeader>Add Employee</DialogHeader>
                <DialogBody>
                    <div className="grid grid-cols-2 gap-4">
                        <div className="flex flex-col">
                            <label for="newEmployee" className="block uppercase text-blueGray-600 text-xs font-bold mb-2">Name <span className="text-red-500">*</span></label>
                            <input type="text" id="newEmployee" name="newEmployee" className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                onChange={(e) => setNewEmployee(e.target.value)}
                                value={newEmployee} />
                        </div>
                    </div>
                </DialogBody>
                <DialogFooter>
                    <Button
                        variant="text"
                        color="red"
                        onClick={() => setShowAddEmployeePopup(false)}
                        className="mr-1"
                    >
                        <span>Cancel</span>
                    </Button>
                    <Button variant="gradient" color="green" onClick={saveEmployeeInternal}>
                        <span>Save</span>
                    </Button>
                </DialogFooter>
            </Dialog>

            <Dialog open={showLogAttendancePopup}>
                <DialogHeader>Log Attendance</DialogHeader>
                <DialogBody>
                    <div className="flex flex-wrap lg:justify-start sm:flex-wrap p-2" >
                        <div className="w-50 mr-3 mt-3 mb-3">
                            <label htmlFor="selectedEmployee" className="block uppercase text-blueGray-600 text-xs font-bold mb-2">Employee Name <span className="text-red-500">*</span></label>
                            <input type="text" id="amount" name="amount"
                                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                value={selectedEmployee} readOnly={true} />
                        </div>
                        <div className="w-50 mr-3 mt-3 mb-3">
                            <label htmlFor="selectedEmployee" className="block uppercase text-blueGray-600 text-xs font-bold mb-2">Date  <span className="text-red-500">*</span></label>
                            <input type="date" className="border-0 ml-2 mr-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                value={attendanceDate} onChange={(event) => setAttendanceDate(event.target.value)} />
                        </div>
                        <div className="w-50 mr-3 mt-3 mb-3">
                            <label htmlFor="selectedEmployee" className="block uppercase text-blueGray-600 text-xs font-bold mb-2">Shifts  <span className="text-red-500">*</span></label>
                            <Checkbox label="Day" checked={dayShift} onChange={() => setDayShift(!dayShift)} />
                            <Checkbox label="Night" checked={nightShift} onChange={() => setNightShift(!nightShift)} />
                        </div>
                    </div>
                </DialogBody>
                <DialogFooter>
                    <Button
                        variant="text"
                        color="red"
                        onClick={() => setShowLogAttendancePopup(false)}
                        className="mr-1"
                    >
                        <span>Cancel</span>
                    </Button>
                    <Button variant="gradient" color="green" onClick={logAttendanceInternal}>
                        <span>Save</span>
                    </Button>
                </DialogFooter>
            </Dialog>
        </div>
    )
}
