import React, { useEffect } from 'react';
import { Card, CardHeader, CardBody, Typography, CardFooter } from '@material-tailwind/react';
import Chart from "react-apexcharts";

export default function StatisticsChart({ id, color, chart, footer }) {



  return (
    <Card className="border border-blue-gray-100 shadow-sm">
      <CardHeader variant="gradient" color={color} floated={false} shadow={false}>
        <Chart chartId={id} {...chart} />
      </CardHeader>
      {footer && (
        <CardFooter className="border-t border-blue-gray-50 px-6 py-5">
          {footer}
        </CardFooter>
      )}
    </Card>
  )
}
